import React, { useState } from "react";
import { createUser, getSuperUsers, postNotices } from "../../utils/api";
import Auth from "../../utils/auth";
import { reroute } from "../../utils/reroute";

const Signup = () => {
  // Form States
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [user_type, setUserType] = useState("");
  const [emailAlert, setEmailAlert] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather Data for query
    const formData = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      password: password,
      user_type: user_type,
    };

    // console.log("Submitting Form", formData);

    // Try to Sign the User up
    try {
      const response = await createUser(formData);

      if (!response.ok) {
        const errorMessage = await response.json();
        const message = errorMessage.errors[0].message;

        // Handle Unique Email Validation Error
        if (message === "email must be unique") {
          console.log("Email Should be Unique");
          document.getElementById("email").focus();
          setEmailAlert(true);
        }

        throw new Error("something went wrong!");
      }

      const { token, user_type, id } = await response.json();

      if (id && user_type === "Instructor") {
        const response = await getSuperUsers();
        const data = await response.json();
        const super_user_id = await data[0].user_id;

        // Gather Notice Data
        const insData = {
          instructor_id: id,
        };

        const noticeData = {
          content: `${name} created an Instructor Account`,
          actions: "Approve Instructor",
          actions_content: JSON.stringify(insData),
          user_id: super_user_id,
        };

        postNotices(noticeData).catch((err) => console.log(err));
      }

      // If successfull, save token and re-route to their "dashboard"
      Auth.login(token);
      reroute(user_type);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form className="login--form" onSubmit={handleFormSubmit}>
      {/* Name */}
      <label htmlFor="name">Name:</label>
      <br></br>
      <input
        type="text"
        id="name"
        name="name"
        defaultValue={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <br></br>

      {/* Address */}
      <label htmlFor="address">Address:</label>
      <br></br>
      <input
        type="text"
        id="address"
        name="address"
        defaultValue={address}
        onChange={(e) => setAddress(e.target.value)}
        required
      />
      <br></br>

      {/* Phone Number */}
      <label htmlFor="phone">Phone Number:</label>
      <br></br>
      <input
        type="tel"
        id="phone"
        name="phone"
        defaultValue={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
      />
      <br></br>

      {emailAlert === true ? (
        <span>
          <p className="email-alert">*** Email address already in use. ***</p>
        </span>
      ) : (
        <></>
      )}

      {/* Email */}
      <label htmlFor="email">Email:</label>
      <br></br>
      <input
        type="email"
        id="email"
        name="email"
        defaultValue={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <br></br>

      {/* Password */}
      <label htmlFor="password">Password:</label>
      <br></br>
      <input
        type="password"
        id="password"
        name="password"
        defaultValue={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <br></br>

      {/* Time Frame */}
      <label htmlFor="timeframe">What best describes you?</label>
      <br></br>
      <input
        type="radio"
        id="student"
        name="user_type"
        value="Student"
        onClick={(e) => setUserType(e.target.value)}
        required
      />
      <label htmlFor="student">Student</label>
      <br></br>
      <input
        type="radio"
        id="counselor"
        name="user_type"
        value="Instructor"
        onClick={(e) => setUserType(e.target.value)}
      />
      <label htmlFor="counselor">Counselor</label>
      <br></br>
      <input
        type="radio"
        id="officer"
        name="user_type"
        value="Parole Officer"
        onClick={(e) => setUserType(e.target.value)}
      />
      <label htmlFor="officer">Probation Officer</label>
      <br></br>

      <input className="signup--form--button" type="submit" value="Sign Up" />
    </form>
  );
};

export default Signup;
