import React, { useState, useEffect } from "react";
import { getMeetings } from "../../utils/api";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const ViewSession = ({ closeModal, session, user }) => {
  const [isLoading, setLoading] = useState(true);

  // Values
  const previousStartDate = session.start_date;
  const previousEndDate = session.end_date;
  const [values, setValues] = useState([]);

  // Run Query Once
  useEffect(() => {
    const dbMeetings = getMeetings(session.id)
      .then((res) => res.json())
      .then((data) => {
        // Display previous values to the calendar
        if (data[0]) {
          const existingMeetings = data.map(
            (meeting) => new Date(meeting.date)
          );

          setValues(existingMeetings);
        }
      })
      .catch((err) => console.log(err));

    Promise.all([dbMeetings]).then(() => {
      setLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="modal--fixed">
              <div className="class-enrollment--container">
                <button
                  className="close-btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  X
                </button>

                <h1>Viewing Session</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section>
      <div className="dashboard">
        <div className="dashboard_content">
          <div className="modal--fixed">
            <div className="class-enrollment--container">
              <button
                className="close-btn"
                onClick={() => {
                  closeModal();
                }}
              >
                X
              </button>

              <h1>Viewing Session</h1>
              <p className="edit-session-details">
                {session.course.name} <br></br>
                {session.start_date} - {session.end_date}
              </p>

              <div className="grid-1X2">
                <div>
                  <p>Instructor: {session.instructor.user.name}</p>
                  <p>Class Meeting Time: {session.time}</p>
                  {session.instructor.user.id === user.id ? (
                    <div>
                      {/* Date Selection */}
                      <p>In Class Meeting Dates:</p>
                      <div>
                        <DatePicker
                          multiple
                          minDate={new Date(previousStartDate)}
                          maxDate={new Date(previousEndDate)}
                          format="MM/DD/YYYY"
                          value={values}
                          placeholder="click to open and choose dates"
                          plugins={[<DatePanel sort="date" />]}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <br />
                </div>

                <div>
                  {/* Date Selection */}
                  <p>Session Start and End date:</p>
                  <div>
                    <Calendar value={[previousStartDate, previousEndDate]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewSession;
