import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import {
  getCourses,
  getLessonbyId,
  updateLesson,
  updateLessonList,
} from "../../utils/api";
import { useNavigate } from "react-router-dom";

const EditLesson = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [lessonListId, setLessonListId] = useState();

  // Form States
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [previousTitle, setPreviousTitle] = useState("");
  const [previousContent, setPreviousContent] = useState("");
  const [courseName, setCourseName] = useState("");
  const [selectedCourses, setselectedCourses] = useState("");
  const [previousSelectedCourses, setPreviousSelectedCourses] = useState("");

  // Session Id
  const url = window.location.href;
  const lesson_id = url.split("/")[5];

  // Calculate what needs to be changed
  const calcChanges = () => {
    // 0 = don't update anything
    // 1 = update lesson
    // 2 = update lesson-list
    // 3 = update both

    let sum = 0;

    if (title !== previousTitle || content !== previousContent) {
      sum += 1;
    }
    if (selectedCourses !== previousSelectedCourses) {
      sum += 2;
    }

    return sum;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather Data for query
    const lessonData = {
      title: title,
      content: content,
    };
    const lessonListData = { course_id: parseInt(selectedCourses) };

    // Sequelize will throw an error if you try to update a model with the same data
    const updates = calcChanges();

    // Handle Update
    switch (updates) {
      case 0:
        // Nothing to Update, Redirect to Lessons Page
        navigate("/lessons");
        break;
      case 1:
        // Only update the Lesson
        updateLesson(lessonData, lesson_id)
          .then(() => {
            navigate("/lessons");
          })
          .catch((err) => console.log(err));
        break;
      case 2:
        // Only update the Lesson List
        updateLessonList(lessonListData, lessonListId)
          .then(() => {
            navigate("/lessons");
          })
          .catch((err) => console.log(err));
        break;
      default:
        // Update Both
        updateLesson(lessonData, lesson_id)
          .then(() => {
            updateLessonList(lessonListData, lessonListId).then(() => {
              navigate("/lessons");
            });
          })
          .catch((err) => console.log(err));
        break;
    }
  };

  useEffect(() => {
    // Get Courses
    getCourses(user.id)
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((err) => console.log(err));

    // Get Lesson
    getLessonbyId(lesson_id)
      .then((res) => res.json())
      .then((data) => {
        // Set Form states
        setTitle(data.title);
        setPreviousTitle(data.title);
        setContent(data.content);
        setPreviousContent(data.content);
        setCourseName(data.courses[0].name);
        setselectedCourses(data.courses[0].id);
        setPreviousSelectedCourses(data.courses[0].id);
        setLessonListId(data.courses[0].lesson_list.id);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />
      {/* Main EditLesson Content*/}
      <section style={{ paddingTop: "50px" }}>
        <form
          style={{ zIndex: "9999" }}
          className="login--form"
          onSubmit={handleFormSubmit}
        >
          {/* Course Association */}
          <label htmlFor="course">Select a course:</label>
          <br></br>
          <select
            name="course"
            id="course"
            required
            className="create-lesson--select"
            onChange={(e) => setselectedCourses(e.target.value)}
          >
            <option value={previousSelectedCourses} hidden>
              {courseName}
            </option>
            {/* Display Course Options */}
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.name}
              </option>
            ))}
          </select>
          <br></br>

          {/* Title */}
          <label htmlFor="title">Title:</label>
          <br></br>
          <input
            type="text"
            id="title"
            name="title"
            defaultValue={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <br></br>

          {/* Content */}
          <label htmlFor="content">Content:</label>
          <br></br>
          <textarea
            className="form--textarea"
            id="content"
            name="content"
            defaultValue={content}
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea>
          <br></br>

          <div className="lesson-form-btn_container">
            <button
              className="lesson--form-cancelbtn"
              onClick={() => navigate("/lessons")}
            >
              Cancel
            </button>

            <input
              className="lesson--form-savebtn"
              type="submit"
              value="Save"
            />
          </div>
        </form>
      </section>
    </>
  );
};

export default EditLesson;
