/*
Expects:
lessons      [{}, {}] <-- an array of lesson objects
lessonOrder  []       <-- an array of numbers (lesson id's)
*/
export const getOrderedLessons = (lessons, lessonOrder) => {
  const lessonIds = [];
  const thisSessionIds = []; // Ordered array of id's for the session
  const orderedLessons = []; // Final array of ordered lessons

  lessons.map((lesson) => lessonIds.push(lesson.id));

  // Get lesson order for this session only
  lessonOrder.map((lessonId) => {
    if (lessonIds.includes(lessonId)) {
      thisSessionIds.push(lessonId);
    }
    return 1;
  });

  // Order the lessons
  thisSessionIds.map((id) => {
    const index = lessonIds.indexOf(id);
    if (index !== -1) {
      orderedLessons.push(lessons[index]);
    }
    return 1;
  });

  if (orderedLessons.length === 0) {
    return lessons;
  } else {
    return orderedLessons;
  }
};
