import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import { getStudentCertificates } from "../../utils/api";

const Certificates = ({ user }) => {
  const [certificates, setCertificates] = useState();
  const [isLoading, setLoading] = useState(true);

  // Run Query Once
  useEffect(() => {
    getStudentCertificates(user.student_id)
      .then((res) => res.json())
      .then((data) => {
        // Only update state if certifictes return from the db
        if (data[0] !== undefined) {
          // Set certificates from database to state
          setCertificates(data[0].uploads);
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Certificates Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="class-card--container">
              {/* Only display if certifictes return from the db */}

              {certificates !== undefined ? (
                <>
                  {/* Loop through certificates from the database for display */}
                  {certificates.map((certificate) => (
                    <CertificatesCard
                      key={certificate.id}
                      certificate={certificate}
                    />
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const CertificatesCard = ({ certificate }) => {
  // On click, open the file in a new window for the user to download
  const openCert = () => {
    const url = `https://www.absolutelypowerfulclasses.com/${certificate.location}`;
    console.log(url);
    window.open(url, "_blank");
  };

  return (
    <div className="class-card">
      <h2 className="class-card-heading">{certificate.display_name}</h2>
      <p>{certificate.date}</p>
      <hr></hr>
      <button className="class-card--btn" onClick={openCert}>
        Download Certificate
      </button>
    </div>
  );
};

export default Certificates;
