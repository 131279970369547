import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import AddSession from "./AddSession";
import EditSession from "./EditSession";
import EditCurrentSession from "./EditCurrentSession";

import { getUpcomingMeetings, getCurrentSessions } from "../../utils/api";

const UpcomingSessions = ({ user }) => {
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [currentMeetings, setcurrentMeetings] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // Modal State
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditCurrentModalOpen, setIsEditCurrentModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const openModal = (session, isView) => {
    setSelectedSession(session);
    if (isView === true) {
      setIsEditCurrentModalOpen(true);
    } else {
      setIsEditModalOpen(true);
    }
  };

  useEffect(() => {
    // GET route for upcoming sessions
    getUpcomingMeetings()
      .then((res) => res.json())
      .then((data) => {
        setUpcomingMeetings(data);
        getCurrentSessions()
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setcurrentMeetings(data);
            setLoading(false);
          });
      })
      .catch((err) => console.log(err));
  }, []);

  const SessionRow = ({ session, isView }) => {
    const { instructor, course, start_date, end_date, time } = session;

    const userName = instructor?.user?.name || "";

    return (
      <tr>
        <td>{userName}</td>
        <td>{course.name}</td>
        <td>
          {start_date} - {end_date}
          <br />
          {time}
        </td>
        <td>
          <button
            onClick={() => openModal(session, isView)}
            className="edit-session-btn"
          >
            <span className="material-symbols-outlined">edit_note</span>
          </button>
        </td>
      </tr>
    );
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            {/* Heading */}
            <div className="SU-upcoming-header">
              <h1>Upcomming Sessions</h1>

              <button onClick={() => setIsCreateModalOpen(true)}>
                Add A Session
              </button>
            </div>

            {/* Upcoming Sessions Table */}
            <div>
              <h2 className="hidden">Upcoming Sessions</h2>
              <table className="SU-upcoming-table">
                <tbody>
                  <tr>
                    <th>Instructor</th>
                    <th>Session</th>
                    <th>Date and Time</th>
                    <th>Edit</th>
                  </tr>

                  {upcomingMeetings.length ? (
                    <>
                      {upcomingMeetings.map((session) => (
                        <SessionRow
                          key={session.id}
                          session={session}
                          isView={false}
                        />
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>No Meetings available</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <h2>Sessions in Progress</h2>
            {/* Current Sessions Table */}
            <div>
              <h2 className="hidden">Current Sessions</h2>
              <table className="SU-upcoming-table">
                <tbody>
                  <tr>
                    <th>Instructor</th>
                    <th>Session</th>
                    <th>Date and Time</th>
                    <th>Edit</th>
                  </tr>

                  {currentMeetings.length ? (
                    <>
                      {currentMeetings.map((session) => (
                        <SessionRow
                          key={session.id}
                          session={session}
                          isView={true}
                        />
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>No Meetings available</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Create Session Modal */}
            {isCreateModalOpen && (
              <AddSession
                isOpen={isCreateModalOpen}
                closeModal={() => setIsCreateModalOpen(false)}
              />
            )}

            {/* Edit Session Modal */}
            {isEditModalOpen && (
              <EditSession
                isOpen={isEditModalOpen}
                closeModal={() => setIsEditModalOpen(false)}
                session={selectedSession}
                user={user}
              />
            )}

            {isEditCurrentModalOpen && (
              <EditCurrentSession
                isOpen={isEditCurrentModalOpen}
                closeModal={() => setIsEditCurrentModalOpen(false)}
                session={selectedSession}
                user={user}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default UpcomingSessions;
