import React from "react";
import { Link } from "react-router-dom";
const Footer = ({ user }) => {
  return (
    <section>
      <div className="footer">
        <div className="footer-left">
          <p className="footer-address">
            <strong style={{ fontSize: "1.5rem" }}>Contact Us</strong>
            <br></br>
            <strong>Absolutely Powerful Classes</strong>
            <br></br>
            7745 Boulder Ave. #863<br></br>Highland, CA. 92346<br></br>
            <br></br>
            2130 N Arrowhead Ave Suite 201D<br></br>San Bernardino, California
            92405<br></br>
            <br></br>
            <strong>Phone:</strong> 909-649-5472
            <br></br>
          </p>
        </div>
        <div className="footer-right">
          <p className="footer-hours">
            <strong>Hours:</strong>
            <br></br> Monday through Friday<br></br>8am to 5pm
          </p>
          <div className="footer-nav-links">
            <Link to="/privacy-policy" className="footer-certificates">
              <strong>Privacy Policy</strong>
            </Link>
            {/* Only display Certificates link if the user is logged in (Client Request)*/}
            {user !== undefined ? (
              <Link
                to="/provider-certifications"
                className="footer-certificates"
              >
                <strong>Certificates</strong>
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="footer-center">
          <img
            className="footer-image"
            alt="Absolutely Powerful Logo"
            src={require(`../../assets/Absolutely-Powerful-Classes-4.webp`)}
            height="272"
            width="272"
          />
          <p
            className="brain-jar-link"
            style={{ fontSize: ".75rem", marginBottom: "0px" }}
          >
            Built By{" "}
            <a
              style={{
                textDecoration: "none",
                color: "dodgerblue",
                marginBottom: "0px",
              }}
              href="https://brainjar.net/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Brain Jar
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
