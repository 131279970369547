import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import { getPastMeetings, getStudentList } from "../../utils/api";
import StudentList from "./StudentList";

const PastSessions = ({ user }) => {
  // State to hold previous Meetings
  const [pastMeetings, setPastMeetings] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const openModal = (session_id) => {
    console.log(user.id, session_id);
    // GET info for Student List page
    getStudentList(user.id, session_id)
      .then((res) => res.json())
      .then((data) => {
        // Pass to modal and open
        setSelectedSession(data);
        setIsModalOpen(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // GET route for Previous Meetings
    getPastMeetings(user.id)
      .then((res) => res.json())
      .then((data) => {
        setPastMeetings(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const SessionRow = ({ session }) => {
    return (
      <tr>
        <td>{session.session.course.name}</td>
        <td>
          {session.date} {session.time}
        </td>
        <td>
          <button
            className="assignment-table--btn"
            onClick={() => openModal(session.session_id)}
          >
            Student List
          </button>
        </td>
      </tr>
    );
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h1>Past Sessions</h1>

            <div>
              <h2 className="hidden">Past Sessions</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Session</th>
                    <th>Date and Time</th>
                    <th>Student List</th>
                  </tr>

                  {pastMeetings.length ? (
                    <>
                      {pastMeetings.map((session) => (
                        <SessionRow key={session.id} session={session} />
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>No Sessions available</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {isModalOpen && (
              <StudentList
                user={user}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                session={selectedSession}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PastSessions;
