import React, { useState, useEffect } from "react";
import { getCourses, getInstructors, createSession } from "../../utils/api";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const AddSession = ({ closeModal }) => {
  const [courses, setCourses] = useState();
  const [instructors, setInstructors] = useState();
  const [isLoading, setLoading] = useState(true);

  // Form States
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedInstructor, setSelectedInstructor] = useState();
  const [value, onChange] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState();

  // Form Submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      course_id: selectedCourse,
      instructor_id: selectedInstructor,
      start_date: value[0],
      end_date: value[1],
      time: selectedTime,
    };

    createSession(formData)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  // Run Query Once
  useEffect(() => {
    const dbCourses = getCourses()
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((err) => console.log(err));

    const dbInstructors = getInstructors()
      .then((res) => res.json())
      .then((data) => {
        setInstructors(data);
      })
      .catch((err) => console.log(err));

    Promise.all([dbCourses, dbInstructors]).then(() => {
      setLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="modal--fixed">
              <div className="class-enrollment--container">
                <button
                  className="close-btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  X
                </button>

                <h1>Add a Session</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section>
      <div className="dashboard">
        <div className="dashboard_content">
          <div className="modal--fixed">
            <div className="class-enrollment--container">
              <button
                className="close-btn"
                onClick={() => {
                  closeModal();
                }}
              >
                X
              </button>

              <h1>Add a Session</h1>
              <form onSubmit={handleFormSubmit}>
                <div className="grid-1X2">
                  <div>
                    {/* <!-- Course Selection --> */}
                    <label htmlFor="course">
                      <p>Select a Course:</p>
                    </label>
                    <br />
                    <select
                      name="course"
                      id="course"
                      onChange={(e) => setSelectedCourse(e.target.value)}
                      required
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {courses.map((course) => (
                        <option key={course.id} value={course.id}>
                          {course.name}
                        </option>
                      ))}
                    </select>

                    <br />

                    {/* <!-- Instructor Selection --> */}
                    <label htmlFor="instructor">
                      <p>Select an Instructor:</p>
                    </label>
                    <br />
                    <select
                      name="instructor"
                      id="instructor"
                      required
                      onChange={(e) => setSelectedInstructor(e.target.value)}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {instructors.map((instructor) => (
                        <option key={instructor.id} value={instructor.id}>
                          {instructor.user.name}
                        </option>
                      ))}
                    </select>

                    <br />

                    {/* <!-- Time Selection --> */}
                    <label htmlFor="time">
                      <p>Set a Time:</p>
                    </label>
                    <br />
                    <select
                      name="time"
                      id="time"
                      required
                      onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      <option value="5:00am - 6:00am">5:00am - 6:00am</option>
                      <option value="6:00am - 7:00am">6:00am - 7:00am</option>
                      <option value="7:00am - 8:00am">7:00am - 8:00am</option>
                      <option value="8:00am - 9:00am">8:00am - 9:00am</option>
                      <option value="9:00am - 10:00am">9:00am - 10:00am</option>
                      <option value="10:00am - 11:00am">
                        10:00am - 11:00am
                      </option>
                      <option value="11:00am - 12:00pm">
                        11:00am - 12:00pm
                      </option>
                      <option value="12:00pm - 1:00pm">12:00pm - 1:00pm</option>
                      <option value="1:00pm - 2:00pm">1:00pm - 2:00pm</option>
                      <option value="2:00pm - 3:00pm">2:00pm - 3:00pm</option>
                      <option value="3:00pm - 4:00pm">3:00pm - 4:00pm</option>
                      <option value="4:00pm - 5:00pm">4:00pm - 5:00pm</option>
                      <option value="5:00pm - 6:00pm">5:00pm - 6:00pm</option>
                      <option value="6:00pm - 7:00pm">6:00pm - 7:00pm</option>
                      <option value="7:00pm - 8:00pm">7:00pm - 8:00pm</option>
                    </select>
                    <br />
                  </div>

                  <div>
                    {/* <!-- Date Selection --> */}
                    <label htmlFor="date">
                      <p>Set date:</p>
                    </label>
                    <br />

                    <div>
                      <Calendar onChange={onChange} selectRange value={value} />
                    </div>

                    <br />
                  </div>
                </div>

                <button className="signup-button save-session">
                  Save Session
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddSession;
