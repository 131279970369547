import React from "react";
import DashboardNav from "../../components/DashboardNav";
import { useNavigate } from "react-router-dom";

const LessonPlaceholder = ({ user }) => {
  const navigate = useNavigate();

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Lesson Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content lessons-page ">
            <h1>Check back shortly!</h1>
            <p>
              We're currently in the process of creating this lesson, please
              check back soon for the latest updates! We're committed to
              delivering high-quality educational materials, and we appreciate
              your patience as we finalize this lesson to meet our standards and
              your expectations.
            </p>
            <p>
              If you encounter any issues or if this problem persists, we're
              here to help. Feel free to reach out to us at
              admin@absolutelypowerfulclasses.com.
            </p>

            <div className="coming-soon-btn">
              <button
                className="signup--form--button lesson_btn"
                onClick={() => navigate(`/classes`)}
              >
                Back to classes
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LessonPlaceholder;
