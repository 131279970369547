// Organized by User, User Type Views, and MISC Fetch Requests

// ------------- User Fetch Requests -------------
// Signup Modal
export const createUser = (userData) => {
  return fetch("api/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

// Login Modal
export const loginUser = (userData) => {
  return fetch("api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

// User Profile Pages
export const udpateUser = (userData, user_id) => {
  return fetch(`api/users/${user_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

export const getUserProfile = (user_id) => {
  return fetch(`api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateTokenImage = (user_id) => {
  return fetch(`/api/users/addImage/${user_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// ------------- Student Views Fetch Requests -------------
// Student Profile AND Payment Success Page
export const udpateStudent = (studentData, student_id) => {
  return fetch(`/api/students/${student_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(studentData),
  });
};

// Assessment Success Page
export const getCourseProgress = (id, student_id) => {
  return fetch(`/api/students/${student_id}/classes/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Catalog Page
export const getSessions = () => {
  return fetch("api/sessions", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Certificates Page
export const getStudentCertificates = (student_id) => {
  return fetch(`/api/students/certificates/${student_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Classes Page
export const getStudentClasses = (student_id) => {
  return fetch(`api/students/classes/${student_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Class Registration Page
export const getSession = (id) => {
  return fetch(`/api/sessions/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createStripeSession = (stripeData) => {
  return fetch(`/create-checkout-session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(stripeData),
  });
};

// Documents Page
export const getStudentDocuments = (student_id) => {
  return fetch(`/api/students/documents/${student_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Instructor Feedback Page
export const getResponse = (response_id) => {
  return fetch(`/api/responses/${response_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const putResponse = (response_id, responseData) => {
  return fetch(`/api/responses/${response_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(responseData),
  });
};

// Lessons Page
export const getLessons = () => {
  return fetch("api/lessons", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getRoster = (session_id, student_id) => {
  return fetch(`/api/rosters/${session_id}/${student_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postResponse = (responseData) => {
  return fetch("/api/responses", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(responseData),
  });
};

export const updateRosterLessons = (rosterData, roster_id) => {
  return fetch(`/api/rosters/${roster_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rosterData),
  });
};

// Payment Success Page
export const getSuperUsers = () => {
  return fetch("api/super-user", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postOrders = (orderData) => {
  return fetch("/api/orders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(orderData),
  });
};

// Student Profile Page
export const getStudentProfile = (user_id) => {
  return fetch(`api/users/student/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateStudentTokenImage = (student_id) => {
  return fetch(`/api/students/addImage/${student_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// ------------- Instructor Views Fetch Requests -------------
// Assignment Review Page
export const udpateResponse = (response_id, responseData) => {
  return fetch(`api/responses/${response_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(responseData),
  });
};

// Attendance Modal
export const updateMeetingNotes = (meetingData, meeting_id) => {
  return fetch(`/api/meetings/${meeting_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(meetingData),
  });
};

export const postAttendance = (attendanceData) => {
  return fetch("/api/attendances", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attendanceData),
  });
};

export const updateRosterMeeting = (rosterData, roster_id) => {
  return fetch(`/api/rosters/${roster_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rosterData),
  });
};

// Edit Session Modal
export const createMeeting = (meetingData) => {
  return fetch("/api/meetings", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(meetingData),
  });
};

export const getMeetings = (session_id) => {
  return fetch(`/api/meetings/${session_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteMeetings = (meeting_id) => {
  return fetch(`/api/meetings/${meeting_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const meetingSchedule = () => {
  return fetch(`/api/meetings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Instructor Dashboard Page
export const getNotices = (user_id) => {
  return fetch(`api/notices/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getReviews = (user_id) => {
  return fetch(`api/responses/instructors-review/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getTodaysMeetings = (user_id) => {
  return fetch(`api/instructors/todays-meetings/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Instructor Profile
export const getInstructorProfile = (user_id) => {
  return fetch(`api/users/instructor/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const udpateInstructor = (instructorData, instructor_id) => {
  return fetch(`api/instructors/${instructor_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(instructorData),
  });
};

// Instructor Uploads Page
export const getOfficersNameAndId = () => {
  return fetch(`api/officers/all-with-id`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getStudentsNameAndId = () => {
  return fetch(`api/students/all-with-id`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Past Sessions Page
export const getPastMeetings = (user_id) => {
  return fetch(`api/instructors/past-meetings/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Past Sudents Page
export const getPastStudents = (user_id) => {
  return fetch(`api/instructors/students/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Student List Page
export const getStudentList = (user_id, session_id) => {
  return fetch(
    `/api/instructors/past-meetings/student_list/${user_id}/${session_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

// Upcoming Sessions Page
export const getUpcomingMeetings = () => {
  return fetch(`api/instructors/upcoming-sessions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getCurrentSessions = () => {
  return fetch(`api/instructors/upcoming-sessions/current`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// ------------- Parole Officer Fetch Requests -------------
// Documents Page
export const getOfficerDocuments = (id) => {
  return fetch(`/api/officers/documents/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// ------------- Super User Fetch Requests -------------
// Add Session Modal
export const getInstructors = () => {
  return fetch(`/api/instructors/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createSession = (sessionData) => {
  return fetch("/api/sessions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sessionData),
  });
};

// Create Lessons Page
export const postLesson = (lessonData) => {
  return fetch("/api/lessons", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lessonData),
  });
};

export const getLessonOrder = () => {
  return fetch("/api/lesson-orders", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postLessonOrder = (lessonData) => {
  return fetch("/api/lesson-orders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lessonData),
  });
};

export const updateLessonOrder = (lessonData, lesson_id) => {
  return fetch(`/api/lesson-orders/${lesson_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lessonData),
  });
};

// Dashboard
export const postRoster = (rosterData) => {
  return fetch("api/rosters", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rosterData),
  });
};

export const removeNotice = (notice_id) => {
  return fetch(`/api/notices/${notice_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Edit Lessons Page
export const getLessonbyId = (lesson_id) => {
  return fetch(`/api/lessons/${lesson_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateLesson = (lessonData, lesson_id) => {
  return fetch(`/api/lessons/${lesson_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lessonData),
  });
};

export const updateLessonList = (lessonListData, lessonList_id) => {
  return fetch(`/api/lesson-lists/${lessonList_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lessonListData),
  });
};

// Edit Sessions Modal
export const updateSession = (sessionData, session_id) => {
  return fetch(`/api/sessions/${session_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sessionData),
  });
};

// Lessons Page
export const getLesson = (lesson_id) => {
  return fetch(`/api/lessons/${lesson_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const removeLesson = (lesson_id) => {
  return fetch(`/api/lessons/${lesson_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Activation Buttons
export const disableUser = (user_id) => {
  return fetch(`/api/users/disable/${user_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getInstructorsNameAndId = () => {
  return fetch(`api/instructors`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const ensableUser = (user_id) => {
  return fetch(`/api/users/enable/${user_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// ------------- Misc Fetch Requests -------------
// Home Page
export const getCourses = () => {
  return fetch("/api/courses", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getHomepageCourses = () => {
  return fetch("/api/courses/homepage", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Uploads Pages
export const addFile = (formData) => {
  return fetch("api/uploads/create", {
    method: "POST",
    body: formData,
  });
};

export const postUpload = (uploadData) => {
  return fetch("api/uploads", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(uploadData),
  });
};

export const getAllDocuments = () => {
  return fetch("/api/super-user/documents", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getStudentDocByInst = (user_id) => {
  return fetch(`/api/instructors/documents/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// PaymentSuccess Page
export const postNotices = (noticeData) => {
  return fetch("/api/notices", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(noticeData),
  });
};

// Single Payment Success Page
export const updateRosterClassAmount = (rosterData, roster_id) => {
  return fetch(`/api/rosters/${roster_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rosterData),
  });
};

// Student Info Page
export const getStudentInfo = (student_id) => {
  return fetch(`/api/students/info/${student_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postNotes = (noteData) => {
  return fetch("/api/notes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(noteData),
  });
};

// Instructor Info Page
export const getInstructorInfo = (instructor_id) => {
  return fetch(`/api/instructors/${instructor_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Officer Info Page
export const getOfficerInfo = (officer_id) => {
  return fetch(`/api/officers/${officer_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getOfficerCurrentClients = (user_id) => {
  return fetch(`/api/officers/current-clients/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getOfficerPastClients = (user_id) => {
  return fetch(`/api/officers/past-clients/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getStudents = () => {
  return fetch(`/api/students/all-with-id`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getOfficerRelations = (officer_id) => {
  return fetch(`/api/officer-relations/${officer_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postOfficerRelation = (relationData) => {
  return fetch("/api/officer-relations", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(relationData),
  });
};

export const deleteOfficerRelation = (relation_id) => {
  return fetch(`/api/officer-relations/${relation_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Dismiss a Notice
export const dismissNotice = (notice_id) => {
  return fetch(`/api/notices/dismiss/${notice_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Student Progress Modal
export const getLessonsProgress = (session_id, student_id) => {
  return fetch(`/api/lessons/session/${session_id}/${student_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getMeetingsProgress = (session_id, student_id) => {
  return fetch(`/api/meetings/session/${session_id}/${student_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
