import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import {
  getCourses,
  postLesson,
  getLessonOrder,
  postLessonOrder,
  updateLessonOrder,
} from "../../utils/api";
import { useNavigate } from "react-router-dom";

const CreateLesson = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [lessonOrder, setlessonOrder] = useState();
  const [postLessonOder, setPostLessonOder] = useState();

  // Form States
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedCourses, setselectedCourses] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather Data for query
    const formData = {
      title: title,
      content: content,
      course_id: selectedCourses, // Course id for the Lesson-list Table
    };

    // Try to add the lesson to our database
    try {
      const response = await postLesson(formData);

      if (!response.ok) {
        throw new Error("something went wrong!");
      }

      const responseData = await response.json();

      if (postLessonOder) {
        const arr = [responseData.id];
        const lessonData = { content: JSON.stringify(arr) };

        postLessonOrder(lessonData)
          .then(() => {
            navigate("/lessons");
          })
          .catch((err) => console.log(err));
      } else {
        const lesson_id = lessonOrder.id;

        const unparsedArr = lessonOrder.content;
        const arr = JSON.parse(unparsedArr);
        arr.push(responseData.id);
        const lessonData = { content: JSON.stringify(arr) };

        updateLessonOrder(lessonData, lesson_id)
          .then(() => {
            navigate("/lessons");
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Get Courses
    getCourses(user.id)
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    getLessonOrder()
      .then((res) => res.json())
      .then((data) => {
        if (data.length) {
          setlessonOrder(data[0]);
          setPostLessonOder(false);
        } else {
          setPostLessonOder(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      <div className="dashboard">
        <div className="dashboard_content">
          {/* Main CreateLesson Content*/}
          <section style={{ paddingTop: "50px" }}>
            <form
              style={{ zIndex: "9999" }}
              className="login--form"
              onSubmit={handleFormSubmit}
            >
              {/* Course Association */}
              <label htmlFor="course">Select a course:</label>
              <br></br>
              <select
                name="course"
                id="course"
                required
                className="create-lesson--select"
                onChange={(e) => setselectedCourses(e.target.value)}
              >
                <option value="" hidden>
                  Which Course is this Lesson for?
                </option>
                {/* Display Course Options */}
                {courses.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.name}
                  </option>
                ))}
              </select>
              <br></br>

              {/* Title */}
              <label htmlFor="title">Title:</label>
              <br></br>
              <input
                type="text"
                id="title"
                name="title"
                className="create-lesson--input"
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <br></br>

              {/* Content */}
              <label htmlFor="content">Content:</label>
              <br></br>
              <textarea
                className="form--textarea create-lesson--input"
                id="content"
                name="content"
                defaultValue={content}
                onChange={(e) => setContent(e.target.value)}
                required
              ></textarea>
              <br></br>

              <div className="lesson-form-btn_container">
                <button
                  className="lesson--form-cancelbtn"
                  onClick={() => navigate("/lessons")}
                >
                  Cancel
                </button>

                <input
                  className="lesson--form-savebtn"
                  type="submit"
                  value="Save"
                />
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default CreateLesson;
