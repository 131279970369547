import React from "react";
import { Link } from "react-router-dom";

const StudentList = ({ user, setIsModalOpen, isModalOpen, session }) => {
  const StudentRow = ({ student }) => {
    return (
      <tr>
        <td>
          <Link to={`/student-info/${student.id}`}>{student.name}</Link>
        </td>
        {student.present !== 0 ? <td>{student.present}/10</td> : <td></td>}
        {student.absent !== 0 ? <td>{student.absent}/10</td> : <td></td>}
        {student.excused !== 0 ? <td>{student.excused}/10</td> : <td></td>}
        {student.incomplete !== 0 ? (
          <td>
            {student.incomplete}/{student.class_amount}
          </td>
        ) : (
          <td></td>
        )}
      </tr>
    );
  };

  return (
    <div className="modal--fixed">
      <div className="class-enrollment--container">
        <button
          className="close-btn"
          onClick={() => {
            setIsModalOpen(!isModalOpen);
          }}
        >
          X
        </button>
        <h1>{session.course_name}</h1>
        <h2>Attendance Sheet</h2>

        <div>
          <h2 className="hidden">Attendance Table</h2>

          {/* <!-- Roster Table --> */}
          <table className="attendance-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Present</th>
                <th>Absent</th>
                <th>Excused</th>
                <th>Incomplete</th>
              </tr>

              {session.students.length ? (
                <>
                  {session.students.map((student) => (
                    <StudentRow key={student.id} student={student} />
                  ))}
                </>
              ) : (
                <tr>
                  <td>No Students Enrolled</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>

          {/* <!-- Notes --> */}
          <h2 style={{ fontFamily: "Open Sans" }}>Notes:</h2>
          {session.sessionNotes.map((note) => (
            <div key={note.date}>
              <p>{note.date}</p>
              <p>{note.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentList;
