import React, { useState, useEffect, useRef } from "react";
import DashboardNav from "../../components/DashboardNav";
import {
  getNotices,
  getReviews,
  getTodaysMeetings,
  postRoster,
  removeNotice,
  getStudentsNameAndId,
  getOfficersNameAndId,
  getInstructorsNameAndId,
  postNotices,
  dismissNotice,
  udpateInstructor,
} from "../../utils/api";
import { Link } from "react-router-dom";
// Import Modals
import AssignmentReviewModal from "../InstructorViews/AssignmentReview";
import ClassEnrollment from "../InstructorViews/ClassEnrollment";

const Dashboard = ({ user }) => {
  // Get notices
  const [notices, setNotices] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // Assignment Review State and Modal
  const [reviews, setReviews] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const openModal = (assignment) => {
    setSelectedAssignment(assignment);
    setIsModalOpen(true);
  };

  // Today's Meetings State and Modal
  const [meetings, setMeetings] = useState([]);
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  // Search Bar State
  const [students, setStudents] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [instructors, setInstructors] = useState([]);
  const [officers, setOfficers] = useState([]);
  const openMeetingModal = (meeting) => {
    setSelectedMeeting(meeting);
    setIsMeetingModalOpen(true);
  };

  // Search Bar
  useEffect(() => {
    // Get Students
    getStudentsNameAndId()
      .then((response) => response.json())
      .then((data) => {
        const studentData = data.map((item) => ({
          id: item.student.id,
          name: item.name,
          role: "student",
        }));
        setStudents(studentData);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    // Get instructors
    getInstructorsNameAndId(user.id)
      .then((res) => res.json())
      .then((data) => {
        const instructorsData = data.map((item) => ({
          id: item.id,
          name: item.user.name,
          role: "instructor",
        }));
        setInstructors(instructorsData);
      })
      .catch((err) => console.log(err));
  }, [user.id]);

  useEffect(() => {
    //Get Officers
    getOfficersNameAndId()
      .then((response) => response.json())
      .then((data) => {
        const officerData = data.map((item) => ({
          id: item.officer.id,
          name: item.name,
          role: "officer",
        }));
        setOfficers(officerData);
      })
      .catch((error) => console.log(error));
  }, []);

  // Called when a student, instructor, parole officer is selected from the dropdown
  const handleUserSelect = (selectedUser) => {
    setSearchInput(selectedUser.name);
    setSelectedUser(selectedUser);

    if (selectedUser.role === "student") {
      window.location.href = `/student-info/${selectedUser.id}`;
    } else if (selectedUser.role === "officer") {
      window.location.href = `/officer-info/${selectedUser.id}`;
    } else if (selectedUser.role === "instructor") {
      window.location.href = `/instructor-info/${selectedUser.id}`;
    }
  };

  // Triggered when the search input value changes
  const handleSearchInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchInput(searchValue);
    setSearchTerm(searchValue);

    if (selectedOption === "students") {
      filterUsers(searchValue, students);
    } else if (selectedOption === "instructors") {
      filterUsers(searchValue, instructors);
    } else if (selectedOption === "officers") {
      filterUsers(searchValue, officers);
    }
  };
  // Triggered when the select option changes
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setSearchTerm("");
    setSearchInput("");
    setSelectedOption(selectedOption);
    setFilteredUsers([]);

    if (selectedOption === "students") {
      setFilteredUsers(students);
    } else if (selectedOption === "instructors") {
      setFilteredUsers(instructors);
    } else if (selectedOption === "officers") {
      setFilteredUsers(officers);
    }

    setShowDropdown(false);
    setSelectedUser(null);
  };
  // Create a ref for the search input field
  const inputRef = useRef(null);

  const handleSearchButtonClick = () => {
    setSearchInput("");
    setFilteredUsers([]);
    setShowDropdown(false);
  };
  // Filter the students, instructors, parole officer based on the search value
  const filterUsers = (searchValue, users) => {
    let filtered = [];

    if (searchValue) {
      filtered = users.filter((user) =>
        user.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      filtered = users;
    }

    // Filter based on unique id
    const uniqueFiltered = Array.from(
      new Set(filtered.map((user) => user.id))
    ).map((id) => filtered.find((user) => user.id === id));

    setFilteredUsers(uniqueFiltered);
    setShowDropdown(searchValue !== "");
  };

  useEffect(() => {
    // Get Notices
    getNotices(user.id)
      .then((res) => res.json())
      .then((data) => {
        setNotices(data);
      })
      .catch((err) => console.log(err));

    // Get Reviews
    getReviews(user.id)
      .then((res) => res.json())
      .then((data) => {
        setReviews(data);
      })
      .catch((err) => console.log(err));

    // Get Today's Meetings
    getTodaysMeetings(user.id)
      .then((res) => res.json())
      .then((data) => {
        setMeetings(data);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            {/* Dashboard Header */}
            <div className="instructor--dashboard-header">
              <h1 className="instructor--dashboard-h1">Main Dashboard</h1>

              <form className="instructor--dashboard-searchbar">
                <label htmlFor="students" className="hidden">
                  Type:
                </label>
                <select
                  name="users"
                  id="users"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="">Select an option</option>
                  <option value="students">Students</option>
                  <option value="instructors">Instructors</option>
                  <option value="officers">Probation Officers</option>
                </select>

                <label htmlFor="search-term" className="hidden">
                  Search the site:
                </label>
                <div className="dropdown-container2">
                  <input
                    className="instructor--dashboard-searchbar-input"
                    type="search"
                    id="search-term"
                    placeholder="Search..."
                    name="q"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                    autoComplete="off"
                    ref={inputRef}
                  />
                  {showDropdown && (
                    <div className="dropdown-items">
                      {filteredUsers.map((user) => (
                        <div key={user.id} className="dropdown-item">
                          <Link
                            to={
                              user.role === "student"
                                ? `/student-info/${user.id}`
                                : user.role === "officer"
                                ? `/officer-info/${user.id}`
                                : `/instructor-info/${user.id}`
                            }
                            onClick={() => handleUserSelect(user)}
                          >
                            {user.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <button
                  className="assessment_button"
                  style={{ margin: "0px" }}
                  onClick={handleSearchButtonClick}
                >
                  Search
                </button>
              </form>
            </div>

            <div className="grid-2X2">
              {/* Notices Table */}
              <div>
                <h2>Notices</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Notice</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                    {!notices[0] ? (
                      <>
                        <tr>
                          <td className="color-muted">No new notices</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {notices.map((notice) => (
                          <Notices key={notice.id} notice={notice} />
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Review Table */}
              <div>
                <h2>Review</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Assignment</th>
                      <th>Student</th>
                    </tr>

                    {!reviews[0] ? (
                      <>
                        <tr>
                          <td className="color-muted">
                            No Assignments to review
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {" "}
                        {reviews.map((assignment) => (
                          <AssignmentReview
                            key={assignment.id}
                            assignment={assignment}
                            openModal={openModal}
                          />
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
                {isModalOpen && (
                  <AssignmentReviewModal
                    user={user}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    assignment={selectedAssignment}
                  />
                )}
              </div>

              {/* Today's Schedule Table */}
              <div>
                <h2>Today's Schedule</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Upcoming Classes</th>
                      <th>Date and Time</th>
                      <th>Group Session</th>
                    </tr>

                    {!meetings[0] ? (
                      <>
                        <tr>
                          <td className="color-muted">No Upcoming Meetings</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {" "}
                        {meetings.map((meeting) => (
                          <TodaysMeetings
                            key={meeting.id}
                            meeting={meeting}
                            openMeetingModal={openMeetingModal}
                          />
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
                {isMeetingModalOpen && (
                  <ClassEnrollment
                    user={user}
                    closeModal={() => setIsMeetingModalOpen(false)}
                    isMeetingModalOpen={isMeetingModalOpen}
                    meeting={selectedMeeting}
                  />
                )}
              </div>

              {/* Post Notice Section */}
              <div>
                <h2>Create a Notice</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Create a Notice</th>
                    </tr>

                    <tr>
                      <td className="color-muted">
                        <CreateNotice />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// Assignment Review Component
const AssignmentReview = ({ assignment, openModal }) => {
  return (
    <tr>
      <td>
        <button
          className="assignment-table--btn"
          onClick={() => openModal(assignment)}
        >
          {assignment.lesson.title}
        </button>
      </td>
      <td>{assignment.student.user.name}</td>
    </tr>
  );
};

// Create Notice Component
const CreateNotice = () => {
  // Form states
  const [selectedIns, setSelectedIns] = useState();
  const [message, setMessage] = useState("");
  const [availableInstructors, setAvailableInstructors] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);

  // Check Char length
  useEffect(() => {
    if (message.length <= 255) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [message]);

  useEffect(() => {
    // Get instructors
    getInstructorsNameAndId()
      .then((res) => res.json())
      .then((data) => {
        const instructorsData = data.map((item) => ({
          id: item.user.id,
          name: item.user.name,
        }));
        setAvailableInstructors(instructorsData);
      })
      .catch((err) => console.log(err));
  }, []);

  const formSubmit = (e) => {
    e.preventDefault();

    const noticeData = {
      content: message,
      user_id: selectedIns,
    };

    postNotices(noticeData)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  return (
    <form className="create-notice-form" onSubmit={formSubmit}>
      <div className="create-notice-textarea-container">
        <label htmlFor="notice-message">Message:</label>

        <textarea
          className="create-notice-textarea"
          id="notice-message"
          name="notice-message"
          value={message}
          required
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <p>{message.length} / 255 </p>
      </div>

      <div className="create-notice-selections">
        <div>
          <label htmlFor="notice-ins">Instructor:</label>
          <br></br>
          <select
            name="notice-ins"
            id="notice-ins"
            className="create-notice-select"
            onChange={(e) => setSelectedIns(e.target.value)}
            required
          >
            <option value="">Select an option</option>
            {availableInstructors.map((instructor) => (
              <option key={instructor.id} value={instructor.id}>
                {instructor.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          {canSubmit === false ? (
            <button
              type="submit"
              className="create-notice-btn"
              disabled
              style={{ backgroundColor: "#1c4d7c9e" }}
            >
              Submit
            </button>
          ) : (
            <button type="submit" className="create-notice-btn">
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

// Notices Component
const Notices = ({ notice }) => {
  const actions_content = JSON.parse(notice.actions_content) || null;
  console.log(actions_content);

  // Handle new Student enrollment
  const handleEnrollment = () => {
    postRoster(actions_content)
      .then(() => {
        removeNotice(notice.id)
          .then(() => {
            window.location.reload();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // Handle payment unsuccesfull
  const handleRemoveNotice = () => {
    removeNotice(notice.id)
      .then(() => {
        // window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  // Handle Instructor Approval
  const handleApproval = () => {
    const instructorData = {
      is_approved: true,
    };

    udpateInstructor(instructorData, actions_content.instructor_id)
      .then(() => {
        removeNotice(notice.id)
          .then(() => {
            window.location.reload();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // Switch to return Actions box
  const actionsValue = (action) => {
    switch (action) {
      case !null:
        return `${action}`;
      case "Approve Instructor":
        return (
          <>
            <Link
              to={`/instructor-info/${actions_content.instructor_id}`}
              className="actions-btn"
            >
              View Instructor
            </Link>

            <button onClick={handleApproval} className="actions-btn">
              Approved Instructor
            </button>
            <button onClick={handleRemoveNotice} className="actions-btn">
              Denied Access
            </button>
          </>
        );
      case "Approve Enrollment":
        return (
          <>
            <Link
              to={`/student-info/${actions_content.student_id}`}
              className="actions-btn"
            >
              View Student
            </Link>

            <button onClick={handleEnrollment} className="actions-btn">
              Payment Approved
            </button>
            <button onClick={handleRemoveNotice} className="actions-btn">
              Payment Denied
            </button>
          </>
        );
      default:
        return "---";
    }
  };

  function removeNotice() {
    dismissNotice(notice.id)
      .then((res) => res.json())
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  return (
    <tr>
      <td>
        <button className="notice-dismiss_btn" onClick={() => removeNotice()}>
          X
        </button>{" "}
        {notice.content}
      </td>
      <td>{notice.date}</td>
      <td>{actionsValue(notice.actions)}</td>
    </tr>
  );
};

// Notices Component
const TodaysMeetings = ({ meeting, openMeetingModal }) => {
  const hour = meeting.session.time.split("-")[0].trim();
  return (
    <tr>
      <td>{meeting.session.course.name}</td>
      <td>
        {meeting.date} {hour}
      </td>
      <td>
        <button
          className="assignment-table--btn"
          onClick={() => openMeetingModal(meeting)}
        >
          Zoom
        </button>
      </td>
    </tr>
  );
};

export default Dashboard;
