import dai from "../../assets/Domestic-Abuse-Intervention-Programs-DAIP.jpg";
import naptia from "../../assets/natpia-badge-160.png";
import dv from "../../assets/DVpartner.webp";

const PartnerOrganizations = () => {
  const openDai = () => {
    window.open("https://www.theduluthmodel.org/", "_blank", "noopener");
  };

  const openDv = () => {
    window.open("https://www.cpedv.org/", "_blank", "noopener");
  };

  const openNaptia = () => {
    window.open("https://www.natpia.com/", "_blank", "noopener");
  };

  return (
    <div className="partner-org-badges">
      <img
        src={dai}
        alt="Domestic Abuse Intervention Programs"
        onClick={() => openDai()}
        height="200"
        width="200"
        loading="lazy"
      ></img>
      <img
        src={dv}
        alt="California Partnership to End Domestic Violence"
        className="dv-img"
        onClick={() => openDv()}
        height="100"
        width="347"
        loading="lazy"
      ></img>
      <img
        src={naptia}
        alt="National Parent Instructor Association"
        onClick={() => openNaptia()}
        height="160"
        width="160"
        loading="lazy"
      ></img>
    </div>
  );
};

export default PartnerOrganizations;
