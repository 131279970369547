import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-policy-header">
        <u>Absolutely Powerful Classes Privacy Policy</u>
      </h1>
      <br />
      <br />
      <h4>
        <u>Our Students' Privacy and Anonymity are of Paramount Importance.</u>
      </h4>
      <p>
        A privacy policy is a very important issue in any business. Most Web
        sites consider data that they have harvested as the property of the
        owners of the Web site. Absolutely Powerful Classes along with
        absolutelypowerfulclasses.com understand the issues inherent in this
        attitude. We consider all personally identifiable data (PID) in our
        possession to be a trust, and we will take care of the sensitive data
        entrusted to us. Specifically, it does not matter where we get the PID.
        It is NEVER ours. It belongs to the person to whom it applies. Period.
        End of story. Our registration pages are all processed using a secure
        https site. We also take steps to safeguard customer information
        including, but not limited to, name, last name, address, course
        registration, court ordered courses, birth date, court case number,
        credit card information and billing information. By utilizing our
        website, you are accepting the practices described in this privacy
        policy.
      </p>
      <h4>
        <u>
          Who Gets "Proof of Enrollment", "Progress Report" & "Proof of
          Completion" Information.
        </u>
      </h4>
      <p>
        This is very important information that the courts have required from
        you. Upon registration our students submit to us Court Ordered Classes,
        name, birth date, court information and a court case number(s) which are
        mandatory requirements for all court documents such as: Proof of
        Enrollment, Progress Reports and Proof of Completion. These certificates
        are sent to you, our registrant, and the court or probation office
        assigned to your case. This information goes nowhere else, period. We
        restrict access to your personal and billing information to those
        employees who need to know that information to provide products or
        services to you. We use technological means such as backup files, virus
        detection, eradication software and firewalls to protect against
        unauthorized access or alterations to customer data and we maintain
        policies and procedures covering the proper physical security of
        workplaces and records. We continually strive to maintain complete,
        accurate and up to date information. If ever you believe that our
        records contain inaccurate or incomplete information, please contact us
        at 909-649-5472. We will investigate your concerns and correct any
        inaccuracies in a timely manner. We reserve the right to change this
        policy at any time but would communicate any significant changes to you
        as required by applicable law.
      </p>
      <h4>
        <u>Handling of Web Access Logs</u>
      </h4>
      <p>
        Handling of Web Access Logs Access logs are text files, automatically
        generated by the Web server, that report which pages and files have been
        requested by visitors. These logs do contain the Internet Protocol (IP)
        number of site visitors, but make no effort to match that IP number with
        any other data. In most cases, the IP numbers are only used in the most
        general sense, such as telling us that a visitor was coming in from
        google or yahoo, as opposed to identifying the visitor explicitly.
        However, the number is recorded, and can be accessed in the future. This
        is standard practice at every Web site you ever visit. We promise to
        never abuse this information. The only time that we should ever spend
        any time trying to determine the IP number of a particular visitor is
        when performing a forensic evaluation (usually done if the server is
        attacked.) We use “cookies” on our websites, which consist of pieces of
        data stored on your hard drive to help us improve your access to our
        sites. Cookies can also help us track and target the interests of our
        users to enhance their experience on our sites. However, a cookie is in
        no way linked to any personally identifiable information on our sites.
        This information is collected for the purposes of (a) ensuring/enhancing
        the functionality of our websites and (b) helping with our understanding
        of where our visitors come from, what they are looking for and what
        page(s) of our website they find most useful (c) detecting and
        preventing fraud (d) analyzing and improving our services.
      </p>
      <h4>
        <u>
          What are the privacy policies of sites to which this web site links.
        </u>
      </h4>
      <p>
        We provide links to third party Web sites that we do not control.
        Therefore, we urge you to review the privacy policies posted on these
        third party Web sites at the time you first visit such sites. We assume
        no obligation to review or ensure enforcement or compliance with the
        privacy policy of any Web site to which we link.
      </p>
      <h4>
        <u>Addressing Concerns Over Use Of Personal Data</u>
      </h4>
      <p>
        If you have any questions, comments, or concerns regarding the use of
        your personal data, please email us at
        admin@absolutelypowerfulclasses.com.
      </p>
    </div>
  );
};
export default PrivacyPolicy;
