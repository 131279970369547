import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import { getUpcomingMeetings, getCurrentSessions } from "../../utils/api";
import EditSession from "./EditSesion";
import ViewSession from "./ViewSession";

const UpcomingSessions = ({ user }) => {
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [currentMeetings, setcurrentMeetings] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // Modal State
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const openModal = (session, isView) => {
    setSelectedSession(session);
    if (isView === true) {
      setIsViewModalOpen(true);
    } else {
      setIsEditModalOpen(true);
    }
  };

  useEffect(() => {
    // GET route for upcoming sessions
    getUpcomingMeetings()
      .then((res) => res.json())
      .then((data) => {
        setUpcomingMeetings(data);
        getCurrentSessions()
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setcurrentMeetings(data);
            setLoading(false);
          });
      })
      .catch((err) => console.log(err));
  }, []);

  const SessionRow = ({ session, isView }) => {
    const { instructor, course, start_date, end_date, time } = session;

    const userName = instructor?.user?.name || "";

    return (
      <tr>
        <td>{userName}</td>
        <td>{course.name}</td>
        <td>
          <>
            {start_date} - {end_date}
            <br />
            {time}
          </>
        </td>

        {userName === user.name ? (
          <td>
            <button
              onClick={() => openModal(session, isView)}
              className="edit-session-btn"
            >
              <span className="material-symbols-outlined">edit_note</span>
            </button>
          </td>
        ) : (
          <td>
            <button disabled className="edit-session-btn disabled-btn">
              <span className="material-symbols-outlined">edit_note</span>
            </button>
          </td>
        )}
      </tr>
    );
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }
  return (
    <>
      <DashboardNav user={user} />
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h1>Upcoming Sessions</h1>
            {/* <!-- Upcoming Sessions Table --> */}
            <div>
              <h2 className="hidden">Upcoming Sessions</h2>
              <table className="SU-upcoming-table">
                <tbody>
                  <tr>
                    <th>Instructor</th>
                    <th>Session</th>
                    <th>Date and Time</th>
                    <th>Edit</th>
                  </tr>

                  {upcomingMeetings.map((session) => (
                    <SessionRow
                      key={session.id}
                      session={session}
                      isView={false}
                    />
                  ))}
                </tbody>
              </table>
            </div>

            <h2>Sessions in Progress</h2>
            {/* Current Sessions Table */}
            <div>
              <h2 className="hidden">Current Sessions</h2>
              <table className="SU-upcoming-table">
                <tbody>
                  <tr>
                    <th>Instructor</th>
                    <th>Session</th>
                    <th>Date and Time</th>
                    <th>View</th>
                  </tr>

                  {currentMeetings.length ? (
                    <>
                      {currentMeetings.map((session) => (
                        <SessionRow
                          key={session.id}
                          session={session}
                          isView={true}
                        />
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>No Sessions In Progress</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Edit Session Modal */}
            {isEditModalOpen && (
              <EditSession
                isOpen={isEditModalOpen}
                closeModal={() => setIsEditModalOpen(false)}
                session={selectedSession}
              />
            )}

            {isViewModalOpen && (
              <ViewSession
                isOpen={isViewModalOpen}
                closeModal={() => setIsViewModalOpen(false)}
                session={selectedSession}
                user={user}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default UpcomingSessions;
