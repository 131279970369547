import React, { useState, useEffect } from "react";
import { getCourseProgress } from "../../utils/api";
import DashboardNav from "../../components/DashboardNav";
import { useNavigate } from "react-router-dom";

const AssessmentSuccess = ({ user }) => {
  const url = window.location.href;
  const id = url.split("/")[4];
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState();
  const [nextLesson, setNextLesson] = useState();
  const [isLoading, setLoading] = useState(true);

  // Run Query Once
  useEffect(() => {
    getCourseProgress(id, user.student_id)
      .then((res) => res.json())
      .then((data) => {
        setSessionId(data.id);

        // Array of completed lessons
        const completeLessons = data.course.lessons.filter(
          (lesson) => lesson.responses[0]
        );

        // Array of incomplete lessons
        const incompleteLessons = data.course.lessons.filter(
          (lesson) => !lesson.responses[0]
        );

        if (completeLessons.length < data.students[0].roster.class_amount) {
          setNextLesson(incompleteLessons[0]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Lesson Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h1>Congratulations!</h1>
            <br></br>
            <br></br>
            <br></br>
            <p className="assessment_content">
              Your assessment has been submitted to the instructor!
            </p>
            <p className="assessment_content">
              Check your email for next steps
            </p>

            <div className="assessment--btn_container">
              <button
                className="assessment_button"
                onClick={() => navigate(`/classes`)}
              >
                Back
              </button>
              {nextLesson ? (
                <button
                  className="assessment_button"
                  onClick={() => {
                    navigate(`/lesson/${nextLesson.id}/${sessionId}`);
                  }}
                >
                  Next Lesson
                </button>
              ) : (
                <button
                  className="assessment_button"
                  onClick={() => navigate("/classes")}
                >
                  Back to Classes
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AssessmentSuccess;
