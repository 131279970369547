import React, { useState, useEffect } from "react";
import {
  udpateStudent,
  postOrders,
  postNotices,
  getSuperUsers,
} from "../../utils/api";
import DashboardNav from "../../components/DashboardNav";
import { useNavigate } from "react-router-dom";
import "./payment.css";

const PaymentSuccess = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState();

  // Run Query Once
  useEffect(() => {
    async function fetchData() {
      const response = await getSuperUsers();
      const data = await response.json();
      const super_user_id = await data[0].user_id;

      // Get the enrollment data from local storage
      const enrollmentData = JSON.parse(localStorage.getItem("enrdta"));

      if (enrollmentData === null) {
        navigate("/catalog");
      } else {
        const {
          court_case,
          judge,
          session_id,
          classAmount,
          stripe_session,
          session_name,
          signupClassAmount,
        } = enrollmentData;

        // Gather Student Data
        let runQuery = false;
        const studentData = {};

        if (court_case) {
          studentData.court_case = court_case;
          runQuery = true;
        }
        if (judge) {
          studentData.judge = judge;
          runQuery = true;
        }

        // Gather Order Data
        const orderData = {
          session_id: session_id,
          stripe_session: stripe_session,
          student_id: user.student_id,
          class_amount: classAmount,
        };

        // Gather Notice Data
        const rosterData = {
          session_id: session_id,
          student_id: user.student_id,
          class_amount: classAmount,
          signup_class_amount: signupClassAmount,
        };

        const noticeData = {
          content: `New Student - ${user.name} signed up for ${signupClassAmount} ${session_name} classes! Paid for ${classAmount} class(es).`,
          actions: "Approve Enrollment",
          actions_content: JSON.stringify(rosterData),
          user_id: super_user_id,
        };

        console.log(noticeData);

        // Fetch 1: creates a record of the order and notice for the Super User
        postOrders(orderData)
          .then((response) => response.json())
          .then((data) => {
            setPaymentData(stripe_session);
            // Fetch 2: Create a notice to the super user for Enrollment Approval
            postNotices(noticeData)
              .then(() => {
                localStorage.removeItem("enrdta");

                // Fetch 3: updates the Student model with form data
                if (runQuery === true) {
                  udpateStudent(studentData, user.student_id)
                    .then(() => {
                      setLoading(false);
                    })
                    .catch((err) => console.log(err));
                } else {
                  setLoading(false);
                }
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="payment-success-page">
              <div className="success-icon success-ring-color">
                <span className="checkmark-line line-right"></span>
                <span className="checkmark-line line-short"></span>
              </div>
              <div className="payment-content">
                <h1>Thank you for signing up!</h1>
                <p className="confirmation-number">
                  Confirmation Number: {paymentData}
                </p>

                <hr className="payment-success-hr"></hr>
                <p className="confirmation-message">
                  Your payment is processing and Our staff will be in touch with
                  you shortly. Check your email for next steps!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentSuccess;
