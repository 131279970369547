import React, { useState, useEffect } from "react";
import {
  getStudentProfile,
  udpateUser,
  udpateStudent,
  addFile,
  updateStudentTokenImage,
} from "../../utils/api";
import DashboardNav from "../../components/DashboardNav";
import Auth from "../../utils/auth";

const StudentProfile = ({ user }) => {
  const [isLoading, setLoading] = useState(true);
  // User Data States
  const [userData, setUserData] = useState();
  const [studentData, setStudentData] = useState();
  const [instructorData, setInstructorData] = useState();
  // Holds uploaded profile image
  const [profileImage, setProfileImage] = useState();

  // Format Array for Instructors from db data
  const formatInstructorsArr = (session) => {
    return {
      name: session.instructor.user.name,
      email: session.instructor.user.email,
      phone: session.instructor.user.phone,
    };
  };

  // Run Query Once
  useEffect(() => {
    getStudentProfile(user.id)
      .then((res) => res.json())
      .then((data) => {
        if (data.student.birthday && data.student.birthday !== null) {
          const formattedBithday = data.student.birthday.split("/");
          data.student.birthday = `${formattedBithday[2]}-${formattedBithday[0]}-${formattedBithday[1]}`;
        }

        // Set the from states with db data
        setUserData({
          name: data.name,
          email: data.email,
          phone: data.phone,
          address: data.address,
          image: data.image,
        });
        setStudentData({
          birthday: data.student.birthday,
          parole_officer: data.student.parole_officer,
          court_case: data.student.court_case,
          att_name: data.student.att_name,
          att_email: data.student.att_email,
          att_phone: data.student.att_phone,
        });
        const instructors = data.student.sessions.map(formatInstructorsArr);
        setInstructorData(instructors);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  /*
    Four fetch requests due to different headers types + content:
    1. addFile: uploads the profile image and returns the new file name
    2. udpateUser: updates the user model with form data (+ new file name if applicable)
    3. updateStudentTokenImage: updates the token with new image for display in the header
    4. updateStudent: updates the student with form data
  */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updateModels = async (fileName) => {
      const userFormData = userData;

      // IF a new image is uploaded, add the image name to the user model
      if (fileName !== undefined) {
        userFormData.image = fileName;
      }

      // Fetch 2: Update the User model's data
      const userResponse = await udpateUser(userFormData, user.id);

      // IF a new image is uploaded, generate a new token
      if (fileName !== undefined) {
        const userData = await userResponse.json();
        console.log(userData);

        // Fetch 3: Update the User's token with the new image
        const response = await updateStudentTokenImage(user.student_id);
        const data = await response.json();
        const { token } = await data;
        // If successfull, save the new token
        Auth.login(token);
      }

      // Fetch 4: Update the Student model's data
      const studentResponse = await udpateStudent(studentData, user.student_id);

      console.log(userResponse, studentResponse);
    };

    if (profileImage !== undefined) {
      // Fetch 1: Upload profile photo
      const response = await addFile(profileImage);
      const data = await response.json();
      const fileName = await data.displayName;

      // Complete user fetch with the new file name
      updateModels(fileName).then(() => {
        window.location.reload();
      });
    } else {
      updateModels().then(() => {
        window.location.reload();
      });
    }
  };

  const handleImageChange = (e) => {
    // Don't update state if the user clicks "cancel"
    if (!e.target.files[0]) {
      return;
    }

    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setProfileImage(formData);
  };

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Profile Content */}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <form
              className="profile-form"
              onSubmit={handleFormSubmit}
              encType="multipart/form-data"
            >
              <div className="profile-form-photo-container">
                <img
                  className="profile-form-photo help"
                  src={
                    !userData.image
                      ? "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png"
                      : `${userData.image}`
                  }
                  alt="a student"
                />
                <div className="flexCenter">
                  <input
                    className="profile-form-photo-input"
                    type="file"
                    accept="image/png, image/jpeg"
                    name="file"
                    onChange={handleImageChange}
                  ></input>
                </div>
              </div>

              {/* Name */}
              <div className="one_line">
                <label htmlFor="name">Full Name:</label>
                <br></br>
                <input
                  className="row_one_label"
                  placeholder="First and Last Name"
                  type="text"
                  id="name"
                  name="name"
                  defaultValue={userData.name}
                  onChange={(e) => {
                    setUserData({ ...userData, name: e.target.value });
                  }}
                  required
                />
              </div>

              {/* Address */}
              <div className="one_line">
                <label htmlFor="address">Address:</label>
                <br></br>
                <input
                  className="row_one_label"
                  placeholder="Street Address City, State XXXXX"
                  type="text"
                  id="address"
                  name="address"
                  defaultValue={userData.address}
                  onChange={(e) =>
                    setUserData({ ...userData, address: e.target.value })
                  }
                  required
                />
              </div>

              {/* Phone Number */}
              <div className="one_line">
                <label htmlFor="phone">Phone Number:</label>
                <br></br>
                <input
                  className="row_one_label"
                  placeholder="123-456-7890"
                  type="tel"
                  id="phone"
                  name="phone"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  defaultValue={userData.phone}
                  onChange={(e) =>
                    setUserData({ ...userData, phone: e.target.value })
                  }
                  required
                />
              </div>

              {/* Birthday */}
              <div className="one_line">
                <label htmlFor="birthday">Birthday:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="date"
                  id="birthday"
                  name="birthday"
                  pattern="\d{2}-\d{2}-\d{4}"
                  defaultValue={studentData.birthday}
                  onChange={(e) => {
                    setStudentData({
                      ...studentData,
                      birthday: new Date(`${e.target.value}T00:00:00`),
                    });
                  }}
                />
              </div>

              {/* Email */}
              <div className="one_line">
                <label htmlFor="email">Email:</label>
                <br></br>
                <input
                  className="row_one_label"
                  placeholder="youremail@example.com"
                  type="email"
                  id="email"
                  name="email"
                  defaultValue={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  required
                />
              </div>

              {/* Password */}
              <div className="one_line">
                <label htmlFor="password">Password:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="password"
                  id="password"
                  name="password"
                  onChange={(e) => {
                    // If the user types a password, then removes it, don't send an empty string to the DB
                    if (e.target.value.trim() === "") {
                      delete userData.password;
                    } else {
                      setUserData({
                        ...userData,
                        password: e.target.value.trim(),
                      });
                    }
                  }}
                />
              </div>

              {/* Officer */}
              <div className="one_line">
                <label htmlFor="officer">Probation Officer:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="text"
                  id="officer"
                  name="officer"
                  defaultValue={studentData.parole_officer}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      parole_officer: e.target.value,
                    })
                  }
                />
              </div>

              {/* Court Case */}
              <div className="one_line">
                <label htmlFor="courtCase">Court Case:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="text"
                  id="courtCase"
                  name="courtCase"
                  defaultValue={studentData.court_case}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      court_case: e.target.value,
                    })
                  }
                />
              </div>

              {/* Attorney Name */}
              <div className="one_line">
                <label htmlFor="attorneyName">Attorney Name:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="text"
                  id="attorneyName"
                  name="attorneyName"
                  defaultValue={studentData.att_name}
                  onChange={(e) =>
                    setStudentData({ ...studentData, att_name: e.target.value })
                  }
                />
              </div>

              {/* Attorney Email */}
              <div className="one_line">
                <label htmlFor="attorneyEmail">Attorney Email:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="email"
                  id="attorneyEmail"
                  name="attorneyEmail"
                  defaultValue={studentData.att_email}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      att_email: e.target.value,
                    })
                  }
                />
              </div>

              {/* Attorney Phone */}
              <div className="one_line">
                <label htmlFor="attorneyPhone">Attorney Phone:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="tel"
                  id="attorneyPhone"
                  name="attorneyPhone"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  defaultValue={studentData.att_phone}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      att_phone: e.target.value,
                    })
                  }
                />
              </div>

              {/* Display Instructor info */}
              {instructorData.map((instructor) => (
                <InstructorInfo key={instructor.name} instructor={instructor} />
              ))}

              <div className="submitBtnContainer">
                <input
                  className="signup--form--button"
                  type="submit"
                  value="Submit Changes"
                />
              </div>
            </form>

            {/* Temporary Placement of Logout Button */}
            <div className="logout_center">
              <button className="logout" onClick={() => Auth.logout()}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const InstructorInfo = ({ instructor }) => {
  return (
    <div style={{ marginTop: "1em" }}>
      <div className="one_line">
        <p>
          <b>Instructor Name</b>
        </p>
        <p>{instructor.name}</p>
      </div>
      <div className="one_line">
        <p>
          <b>Instructor Email</b>
        </p>
        <p>{instructor.email}</p>
      </div>
      <div className="one_line">
        <p>
          <b>Instructor Phone</b>
        </p>
        <p>{instructor.phone}</p>
      </div>
    </div>
  );
};

export default StudentProfile;
