import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import { getOfficerDocuments } from "../../utils/api";

const OfficerDocuments = ({ user }) => {
  const [documents, setDocuments] = useState();
  const [isLoading, setLoading] = useState(true);

  // Run Query Once
  useEffect(() => {
    getOfficerDocuments(user.id)
      .then((res) => res.json())
      .then((data) => {
        // Only update state if documemts return from the db
        if (data[0].officer.uploads[0] !== undefined) {
          // Set certificates from database to state
          setDocuments(data[0].officer.uploads);
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="class-card--container">
              {/* Only display if Documents return from the db */}

              {documents !== undefined ? (
                <>
                  {/* Loop through documents from the database for display */}
                  {documents.map((document) => (
                    <DocumentsCard key={document.id} document={document} />
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DocumentsCard = ({ document }) => {
  // On click, open the file in a new window for the user to download
  const openDoc = () => {
    const url = `https://www.absolutelypowerfulclasses.com/${document.location}`;
    window.open(url, "_blank");
  };

  return (
    <div className="class-card">
      <h2 className="class-card-heading">{document.student_name}</h2>
      <p>{document.display_name}</p>
      <p>{document.date}</p>
      <hr className="class-card--hr" />
      <button className="class-card--btn" onClick={openDoc}>
        Download Report
      </button>
    </div>
  );
};

export default OfficerDocuments;
