import React, { useState } from "react";
import { disableUser, ensableUser } from "../../utils/api";

// Super User activation buttons for the Instructor, Student, and Parole Officer "Info" Pages
const ActivationBtns = ({ userId }) => {
  const [notification, setNotification] = useState("");

  const handleDisable = () => {
    disableUser(userId)
      .then(() => {
        setNotification("User Login Disabled");
      })
      .catch((err) => console.log(err));
  };

  const handleEnable = () => {
    ensableUser(userId)
      .then(() => {
        setNotification("User Login Enabled");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="parole-officer--infoBtns">
      <button onClick={() => handleDisable()} className="login--btn">
        Disable Login
      </button>
      <button onClick={() => handleEnable()} className="login--btn-2">
        Enable Login
      </button>
      {notification ? <p>{notification}</p> : null}
    </div>
  );
};

export default ActivationBtns;
