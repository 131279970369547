import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Auth from "./utils/auth";

// General Pages/Components
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Header from "./components/Header";
import Dashboard from "./pages/SuperUserViews/Dashboard";
import Profile from "./pages/Profile";
import About from "./pages/About";
import GroupRules from "./pages/GroupRules";
import ProviderCertificates from "./pages/ProviderCertificates";

// Info Pages
import StudentInfo from "./pages/InfoPages/StudentInfo";
import InstructorInfo from "./pages/InfoPages/InstructorInfo";
import OfficerInfo from "./pages/InfoPages/OfficerInfo";

// Student Pages
import Catalog from "./pages/StudentViews/Catalog";
import Certificates from "./pages/StudentViews/Certificates";
import Uploads from "./pages/StudentViews/Uploads";
import Documents from "./pages/StudentViews/Documents";
import Classes from "./pages/StudentViews/Classes";
import StudentProfile from "./pages/StudentViews/StudentProfile";
import ClassRegistration from "./pages/StudentViews/ClassRegistration";
import Lesson from "./pages/StudentViews/Lesson";
import AssessmentSuccess from "./pages/StudentViews/AssessmentSuccess";
import InstructorFeedback from "./pages/StudentViews/InstructorFeedback";
import PaymentSuccess from "./pages/StudentViews/PaymentSuccess";
import SinglePaymentSuccess from "./pages/StudentViews/SinglePaymentSuccess";
import LessonPlaceholder from "./pages/StudentViews/LessonPlaceholder";

// Super User Pages
import Lessons from "./pages/SuperUserViews/Lessons";
import CreateLesson from "./pages/SuperUserViews/CreateLesson";
import EditLesson from "./pages/SuperUserViews/EditLesson";
import SuperUpcomingSessions from "./pages/SuperUserViews/UpcomingSessions";
import SuperUserUploads from "./pages/SuperUserViews/SuperUserUploads";
import SuperUserProfile from "./pages/SuperUserViews/Profile";

// Instructor Pages
import InstructorProfile from "./pages/InstructorViews/InstructorProfile";
import InstructorDashboard from "./pages/InstructorViews/InstructorDashboard";
import PastSessions from "./pages/InstructorViews/PastSessions";
import PastStudents from "./pages/InstructorViews/PastStudents";
import UpcomingSessions from "./pages/InstructorViews/UpcomingSessions";
import InstructorUploads from "./pages/InstructorViews/InstructorUploads";
import Attendance from "./pages/InstructorViews/Attendance";

// Parole Officer Pages
import CurrentClients from "./pages/OfficerViews/CurentClients";
import OfficerDocuments from "./pages/OfficerViews/Documents";
import PastClients from "./pages/OfficerViews/PastClients";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  let user;

  if (Auth.loggedIn() !== false) {
    user = Auth.getUser();
    // console.log(user);
  }

  // Allow users to access routes IF logged in
  function checkUser() {
    if (user !== undefined) {
      // Conditionally routes based on the user_type
      switch (user.user_type) {
        case "Student":
          return studentRoutes;
        case "Parole Officer":
          return officerRoutes;
        case "Instructor":
          return instructorRoutes;
        case "Super User":
          return superUserRoutes;
        default:
          return null;
      }
    }
  }

  const studentRoutes = (
    <>
      <Route path="/classes" element={<Classes user={user} />} />
      <Route path="/catalog" element={<Catalog user={user} />} />
      <Route path="/uploads" element={<Uploads user={user} />} />
      <Route path="/certificates" element={<Certificates user={user} />} />
      <Route path="/documents" element={<Documents user={user} />} />
      <Route path="/profile" element={<StudentProfile user={user} />} />
      <Route
        path="/class-registration/:id"
        element={<ClassRegistration user={user} />}
      />

      <Route
        path="/instructor-feedback/:id"
        element={<InstructorFeedback user={user} />}
      />
      <Route path="/lesson/:id/:session_id" element={<Lesson user={user} />} />
      <Route
        path="/assessment-success/:session_id"
        element={<AssessmentSuccess user={user} />}
      />
      <Route path="/payment-success" element={<PaymentSuccess user={user} />} />
      <Route
        path="/single-payment-success"
        element={<SinglePaymentSuccess user={user} />}
      />
      <Route
        path="/lesson/comming-soon"
        element={<LessonPlaceholder user={user} />}
      />
      <Route path="/group-rules" element={<GroupRules user={user} />} />
    </>
  );

  const officerRoutes = (
    <>
      <Route path="/profile" element={<Profile user={user} />} />
      <Route path="/dashboard" element={<CurrentClients user={user} />} />
      <Route path="/documents" element={<OfficerDocuments user={user} />} />
      <Route path="/pastClients" element={<PastClients user={user} />} />
      <Route path="/student-info/:id" element={<StudentInfo user={user} />} />
    </>
  );

  const instructorRoutes =
    user && user.is_approved === true ? (
      <>
        <Route path="/attendance" element={<Attendance user={user} />} />
        <Route
          path="/dashboard"
          element={<InstructorDashboard user={user} />}
        />
        <Route path="/profile" element={<InstructorProfile user={user} />} />
        <Route
          path="/upcoming-sessions"
          element={<UpcomingSessions user={user} />}
        />
        <Route path="/past-sessions" element={<PastSessions user={user} />} />
        <Route path="/students" element={<PastStudents user={user} />} />
        <Route path="/uploads" element={<InstructorUploads user={user} />} />
        <Route path="/student-info/:id" element={<StudentInfo user={user} />} />
        <Route
          path="/instructor-info/:id"
          element={<InstructorInfo user={user} />}
        />
        <Route path="/officer-info/:id" element={<OfficerInfo user={user} />} />
        <Route path="/group-rules" element={<GroupRules user={user} />} />
      </>
    ) : (
      <>
        <Route path="/dashboard" element={<InstructorProfile user={user} />} />
        <Route path="/profile" element={<InstructorProfile user={user} />} />
      </>
    );

  const superUserRoutes = (
    <>
      <Route path="/dashboard" element={<Dashboard user={user} />} />
      <Route path="/attendance" element={<Attendance user={user} />} />
      <Route path="/lessons/create" element={<CreateLesson user={user} />} />
      <Route path="/lessons/edit/:id" element={<EditLesson user={user} />} />
      <Route path="/lessons" element={<Lessons user={user} />} />
      <Route path="/profile" element={<SuperUserProfile user={user} />} />
      <Route
        path="/upcoming-sessions"
        element={<SuperUpcomingSessions user={user} />}
      />
      <Route path="/past-sessions" element={<PastSessions user={user} />} />
      <Route path="/students" element={<PastStudents user={user} />} />
      <Route path="/uploads" element={<SuperUserUploads user={user} />} />
      <Route path="/student-info/:id" element={<StudentInfo user={user} />} />
      <Route
        path="/instructor-info/:id"
        element={<InstructorInfo user={user} />}
      />
      <Route path="/officer-info/:id" element={<OfficerInfo user={user} />} />
      <Route path="/group-rules" element={<GroupRules user={user} />} />
    </>
  );

  return (
    <Router>
      <Header user={user} />
      <Routes>
        <Route path="/" element={<Home user={user} />} />
        {checkUser()}
        <Route path="/about-us" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy user={user} />} />
        <Route
          path="/provider-certifications"
          element={<ProviderCertificates user={user} />}
        />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer user={user} />
    </Router>
  );
}

export default App;
