import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  updateMeetingNotes,
  postAttendance,
  updateRosterMeeting,
} from "../../utils/api";

const Attendance = ({
  isAttendanceModalOpen,
  setIsAttendanceModalOpen,
  meeting,
}) => {
  const course_name = meeting.session.course.name;
  const meeting_id = meeting.id;

  const [formState, setformState] = useState({
    students: meeting.session.students,
    absentFees: [],
    notes: "",
  });

  const updateNotes = (e) => {
    formState.notes = e.target.value;
  };

  const updateStudentStatus = (e, student) => {
    const studentState = formState.students.filter(
      (studentObj) => studentObj.id === student.id
    );
    studentState[0].status = e.target.value;
  };

  const StudentRow = ({ student }) => {
    const [isCharged, setIsCharged] = useState(false);
    function handleAbsentFee(e, status) {
      e.preventDefault();

      if (status === true) {
        console.log("charging absent fee!");
        formState.absentFees.push(student.id);
        setIsCharged(true);
      } else {
        console.log("removing absent fee!");
        var studentIndex = formState.absentFees.indexOf(student.id);
        //remove student from the absentee array
        formState.absentFees.splice(studentIndex, 1);
        setIsCharged(false);
      }
    }

    // Only allow a Student to populate for attendance if they still have meetings left
    if (student.roster.class_amount > student.roster.meetings_attended) {
      return (
        <tr>
          <td>
            <Link to={`/student-info/${student.id}`}>{student.user.name}</Link>
          </td>
          <td>
            <input
              type="radio"
              name={student.id}
              value="present"
              onChange={(e) => {
                updateStudentStatus(e, student);
              }}
            />
          </td>
          <td>
            <input
              type="radio"
              name={student.id}
              value="absent"
              onChange={(e) => {
                updateStudentStatus(e, student);
              }}
            />
          </td>
          <td>
            <input
              type="radio"
              name={student.id}
              value="excused"
              onChange={(e) => {
                updateStudentStatus(e, student);
              }}
            />
          </td>
          <td>
            <input
              type="radio"
              name={student.id}
              value="incomplete"
              onChange={(e) => {
                updateStudentStatus(e, student);
              }}
            />
          </td>
          {/* <td>
      
            <div className={isCharged === true ? "hide" : null}>
              <button
                className="absentee-fee_btn"
                onClick={(e) => handleAbsentFee(e, true)}
              >
                Charge Absentee Fee
              </button>
            </div>

            <div className={isCharged === true ? null : "hide"}>
              <button
                className="absentee-fee_btn"
                onClick={(e) => handleAbsentFee(e, false)}
              >
                Undo
              </button>
            </div>
          </td> */}
        </tr>
      );
    } else {
      // If the student has no meetings left to attend, don't return form inputs
      return (
        <tr>
          <td>
            <Link to={`/student-info/${student.id}`}>{student.user.name}</Link>
          </td>
          <td>---</td>
          <td>---</td>
          <td>---</td>
          <td>---</td>
        </tr>
      );
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    console.log(formState);

    if (formState.absentFees.length) {
      console.log("handle absentee fees for students:", formState.absentFees);
    }

    // IF the instructor added notes, update the Meeting notes
    if (formState.notes.trim() !== "") {
      const meetingData = {
        notes: formState.notes.trim(),
      };

      updateMeetingNotes(meetingData, meeting_id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        })
        .catch((err) => console.log(err));
    }

    // Loop through and add attendance record for each student
    const attendances = [];
    const rosters = [];

    formState.students.forEach((student) => {
      if (student.status !== undefined) {
        attendances.push({
          student_id: student.id,
          status: student.status,
          meeting_id: meeting_id,
        });
      }
    });

    formState.students.forEach((student) => {
      if (
        student.roster.class_amount > student.roster.meetings_attended &&
        student.status !== undefined
      ) {
        rosters.push({
          roster_id: student.roster.id,
          rosterData: {
            meetings_attended: student.roster.meetings_attended + 1,
          },
        });
      }
    });

    // Wait until all Attendances are posted, then reload the page
    Promise.all(attendances.map((i) => postAttendance(i)))
      .then(() => {
        // Wait until all Rosters are updated for applicable students
        Promise.all(
          rosters.map((i) => updateRosterMeeting(i.rosterData, i.roster_id))
        )
          .then(() => {
            window.location.reload();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="modal--fixed">
              <div className="class-enrollment--container">
                <button
                  className="close-btn"
                  onClick={() => {
                    setIsAttendanceModalOpen(!isAttendanceModalOpen);
                  }}
                >
                  X
                </button>
                <h1>{course_name}</h1>
                <h2>Attendance Sheet</h2>

                {/* <!-- Roster Table --> */}
                <div>
                  <h2 className="hidden">Attendance Table</h2>
                  <form className="attendance-form" onSubmit={handleFormSubmit}>
                    <table className="attendance-table">
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <th>Present</th>
                          <th>Absent</th>
                          <th>Excused</th>
                          <th>Incomplete</th>
                          {/* <th></th> */}
                        </tr>

                        {formState.students.length ? (
                          <>
                            {formState.students.map((student) => (
                              <StudentRow key={student.id} student={student} />
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td>No Students Enrolled</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <div className="notes-container">
                      <label htmlFor="notes">Notes:</label>
                      <textarea
                        id="notes"
                        className="attendance-notes"
                        name="notes"
                        onChange={(e) => {
                          // setformState({ ...formState, notes: e.target.value });
                          updateNotes(e);
                        }}
                      ></textarea>

                      <button className="intake_button">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Attendance;
