import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import ClientClassEnrollment from "./ClientClassEnrollment";
import { getOfficerPastClients } from "../../utils/api";
import { Link } from "react-router-dom";

const PastClients = ({ user }) => {
  // Past Client State
  const [pastClients, setPastClients] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // Search Bar State
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const openModal = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  useEffect(() => {
    // Get Past Students from the db
    getOfficerPastClients(user.id)
      .then((res) => res.json())
      .then((data) => {
        console.log("Response data:", data);
        if (data[0] && data[0].students) {
          console.log("Students data:", data.students);
          setPastClients(data[0].students);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [user.id]);
  // Filter the students based on the search term
  useEffect(() => {
    const filteredStudents = filterDuplicates(pastClients);
    setSearchResults(filteredStudents);
  }, [pastClients]);
  // Function to filter duplicate students
  const filterDuplicates = (array) => {
    const uniqueStudents = {};
    const filteredArray = [];

    array.forEach((student) => {
      const { name, email, phone } = student.user;
      const key = `${name}_${email}_${phone}`;
      if (!uniqueStudents[key]) {
        uniqueStudents[key] = true;
        filteredArray.push({ id: student.id, name, email, phone });
      }
    });

    return filteredArray;
  };
  // Event handler for input change in search bar

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setShowDropdown(searchTerm !== ""); // Show the dropdown if search term is not empty
    const filteredResults = filterDuplicates(pastClients).filter((student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredResults);
  };
  // Function for rendering the dropdown list of search results

  const renderDropdown = () => {
    if (searchResults.length === 0 || searchTerm === "") {
      return null;
    }

    return (
      <div className={`dropdown-container ${searchTerm ? "show" : ""}`}>
        <ul className="dropdown">
          {searchResults.map((student) => (
            <li
              key={student.id}
              onClick={() => {
                setSearchTerm(student.name);
                setSearchResults([]);
              }}
            >
              <Link
                to={`/student-info/${student.id}`}
                className="dropdown-link"
              >
                {student.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // Fix: stop flashing after state updates
  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="past--sessions-header">
              <h1 className="past--sessions-h1">Past Clients</h1>

              <form className="past--sessions-searchbar po-searchbar">
                <label htmlFor="search-term" className="hidden">
                  Search the site:
                </label>
                <div className="search-wrapper">
                  <input
                    className="past--sessions-searchbar-input"
                    type="search"
                    id="search-term"
                    placeholder="Search..."
                    name="q"
                    value={searchTerm}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {renderDropdown()}
                </div>
                <button className="assessment_button" style={{ margin: "0px" }}>
                  Search
                </button>
              </form>

              {/* Past Clients Table */}
              <div className="pa-past-clients-container">
                <div className="current-clients-container">
                  {pastClients.map((student) => (
                    <StudentDiv
                      key={student.id}
                      student={student}
                      openModal={openModal}
                    />
                  ))}
                </div>
              </div>

              {isModalOpen && (
                <ClientClassEnrollment
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                  student={selectedStudent}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const StudentDiv = ({ student, openModal }) => {
  return (
    <div className="current-clients-div">
      <p className="current-clients-name" onClick={() => openModal(student)}>
        {student.user.name}
      </p>
    </div>
  );
};

export default PastClients;
