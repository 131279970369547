import React, { useState, useEffect } from "react";
import { getLessonsProgress, getMeetingsProgress } from "../../utils/api";
import "./modal.css";

const StudentProgressModal = ({
  setisModalOpen,
  isModalOpen,
  selectedSession,
}) => {
  // console.log(selectedSession);
  const [lessons, setLessons] = useState([]);
  const [meeting, setMeeting] = useState({});
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const session_id = selectedSession.roster.session_id;
    const student_id = selectedSession.roster.student_id;

    // Get Student Info
    getLessonsProgress(session_id, student_id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        if (data.course.lessons[0]) {
          setLessons(data.course.lessons);
        }

        getMeetingsProgress(session_id, student_id)
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);

            const dbMeetings = {
              present: 0,
              absent: 0,
              excused: 0,
              incomplete: 0,
            };

            if (data.meetings[0]) {
              for (let i = 0; i < data.meetings.length; i++) {
                const meet = data.meetings[i];
                if (meet.attendances[0].status) {
                  const status = meet.attendances[0].status;
                  switch (status) {
                    case "present":
                      dbMeetings.present++;
                      break;
                    case "absent":
                      dbMeetings.absent++;
                      break;
                    case "excused":
                      dbMeetings.excused++;
                      break;
                    case "incomplete":
                      dbMeetings.incomplete++;
                      break;
                    default:
                      return;
                  }
                }
              }
            }
            setMeeting(dbMeetings);

            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [selectedSession]);

  const StuLessonRow = ({ lesson }) => {
    // console.log(lesson);
    return (
      <div>
        <details>
          <summary>
            {lesson.title} (<b>{lesson.responses[0].status}</b>)
          </summary>
          <hr></hr>
          <p>
            <b>Student Response:</b> {lesson.responses[0].stu_response}
          </p>
          <p>
            <b>Instructor Feedback:</b>{" "}
            {lesson.responses[0].ins_feedback !== null
              ? lesson.responses[0].ins_feedback
              : "---"}
          </p>
          <p>
            <b>Private Instructor Feedback:</b>{" "}
            {lesson.responses[0].ins_private_notes !== null
              ? lesson.responses[0].ins_private_notes
              : "---"}
          </p>
          <hr></hr>
        </details>
      </div>
    );
  };

  const StuAttendance = () => {
    return (
      <table className="attendance-table">
        <tbody>
          <tr>
            <th>Present</th>
            <th>Absent</th>
            <th>Excused</th>
            <th>Incomplete</th>
          </tr>
          <tr>
            <td>{meeting.present !== 0 ? meeting.present : "---"}</td>
            <td>{meeting.absent !== 0 ? meeting.absent : "---"}</td>
            <td>{meeting.excused !== 0 ? meeting.excused : "---"}</td>
            <td>{meeting.incomplete !== 0 ? meeting.incomplete : "---"}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  if (loading) {
    return (
      <div className="modal--fixed">
        <div className="class-enrollment--container p-0">
          <div className="progress-modal-header">
            <h1>
              {selectedSession.course.name} {selectedSession.start_date} -{" "}
              {selectedSession.end_date}
            </h1>
            <button
              className="sp-close-btn"
              onClick={() => {
                setisModalOpen(!isModalOpen);
              }}
            >
              X
            </button>
          </div>
          <p>loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="modal--fixed">
      <div className="class-enrollment--container p-0">
        <div className="progress-modal-header">
          <h1>
            {selectedSession.course.name} {selectedSession.start_date} -{" "}
            {selectedSession.end_date}
          </h1>
          <button
            className="sp-close-btn"
            onClick={() => {
              setisModalOpen(!isModalOpen);
            }}
          >
            X
          </button>
        </div>

        <div className="p-10_40">
          <div className="progress-info-container">
            <p>
              <b>Enrolled in:</b>{" "}
              {selectedSession.roster.signup_class_amount !== null
                ? selectedSession.roster.signup_class_amount + " classes"
                : "---"}
            </p>
            <p>
              <b>Paid for:</b>{" "}
              {selectedSession.roster.class_amount !== null
                ? selectedSession.roster.class_amount + " classes"
                : "---"}
            </p>
            <p>
              <b>Status:</b> {selectedSession.roster.status}
            </p>
          </div>

          <div>
            <p className="progress-heading">
              Lessons Submitted:{" "}
              {selectedSession.roster.signup_class_amount !== null
                ? selectedSession.roster.lessons_complete +
                  "/" +
                  selectedSession.roster.signup_class_amount
                : selectedSession.roster.lessons_complete +
                  "/" +
                  selectedSession.roster.class_amount}
            </p>
            <div className="lessons-progress-container">
              {lessons.map((lesson) => (
                <StuLessonRow key={lesson.id} lesson={lesson} />
              ))}
            </div>
          </div>

          <div>
            <p className="progress-heading">
              Meetings Attended:{" "}
              {selectedSession.roster.signup_class_amount !== null
                ? selectedSession.roster.meetings_attended +
                  "/" +
                  selectedSession.roster.signup_class_amount
                : selectedSession.roster.meetings_attended +
                  "/" +
                  selectedSession.roster.class_amount}
            </p>
            <div className="meeting-table-container">
              <StuAttendance />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProgressModal;
