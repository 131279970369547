import React, { useState } from "react";
import { loginUser } from "../../utils/api";
import { reroute } from "../../utils/reroute";
import Auth from "../../utils/auth";

const Login = () => {
  // Form States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather Data for query
    const formData = {
      email: email,
      password: password,
    };

    // Try to Log the User in
    try {
      const response = await loginUser(formData);

      // Handle unsuccesfull login
      if (!response.ok) {
        const { message } = await response.json();
        if (message === "Incorrect password for this account.") {
          setErrorMessage("Incorrect credentials for this account.");
        } else if (message === "There is no account found with this email.") {
          setErrorMessage("There is no account found with this email.");
        } else {
          setErrorMessage("Something has gone wrong, please try again.");
        }
      }

      const { token, user_type } = await response.json();

      // If successfull, save token and re-route to their "dashboard"
      Auth.login(token);
      reroute(user_type);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form className="login--form" onSubmit={handleFormSubmit}>
      {/* Email */}
      <label htmlFor="email">Email:</label>
      <br></br>
      <input
        type="email"
        id="email"
        name="email"
        defaultValue={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <br></br>

      {/* Password */}
      <label htmlFor="password">Password:</label>
      <br></br>
      <input
        type="password"
        id="password"
        name="password"
        defaultValue={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <br></br>

      <input className="signup--form--button" type="submit" value="Log in" />

      {/* Display Error Message */}
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
    </form>
  );
};

export default Login;
