import React, { useState, useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import { meetingSchedule } from "../../utils/api";

const ClassSchedule = () => {
  // state for schedules and selected days
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    meetingSchedule()
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        const formattedData = data.map((d) => ({
          date: d.date,
          value: `${d.session.course.name}, ${d.session.time}`,
        }));
        setSchedules(formattedData);
      });
  }, []);
  // Set the selected date when a date is clicked
  const handleDateChange = (date) => {
    setSelectedDate(date.format("MM/DD/YYYY"));
  };
  // Find the schedule for the selected date
  // const selectedSchedule = schedules.find(
  //   (schedule) => schedule.date === selectedDate
  // );

  const selectedSchedule = schedules.filter(
    (schedule) => schedule.date === selectedDate
  );

  return (
    <div className="container">
      <div className="calendar-container">
        <Calendar
          selectedDate={selectedDate}
          onChange={handleDateChange}
          mapDays={({ date, isToday, isSelected, isOutOfRange }) => {
            return (
              <div
                className={`rmdp-day${isToday ? " rmdp-today" : ""}${
                  isSelected ? " rmdp-selected" : ""
                }${isOutOfRange ? " rmdp-out-of-range" : ""}`}
              >
                {date.format("D")}
              </div>
            );
          }}
        />
        <div style={{ marginLeft: "20px", width: "250px" }}>
          {selectedSchedule.length ? (
            <div style={{ overflow: "auto", height: "250px" }}>
              <h3>Details</h3>
              {selectedSchedule.map((assignment) => (
                <div>
                  <p>
                    Date: {assignment.date}
                    <br></br>Session Time: {assignment.value}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p>Please select a date</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassSchedule;
