import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import DashboardNav from "../../components/DashboardNav";
import {
  getStudentsNameAndId,
  getOfficersNameAndId,
  addFile,
  postUpload,
} from "../../utils/api";
import UploadsByStudents from "./UploadsByStudents";
// Allowed types of files for upload
const fileTypes = ["JPG", "PNG", "PDF"];

const InstructorUploads = ({ user }) => {
  // Student and Officers from the db
  const [students, setStudents] = useState([]);
  const [officers, setOfficers] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // Get All Students
    getStudentsNameAndId()
      .then((res) => res.json())
      .then((data) => {
        setStudents(data);
      })
      .catch((err) => console.log(err));

    // Get All Officers
    getOfficersNameAndId()
      .then((res) => res.json())
      .then((data) => {
        setOfficers(data);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h1>Uploads</h1>

            {/* Main Instructor Uploads Content*/}
            <div className="ins-uploads-page grid-3sp">
              <StudentUploads students={students} user={user} />
              <div className="ins-uploads-divider">
                <hr></hr>
              </div>
              <ParoleOfficerUploads
                officers={officers}
                students={students}
                user={user}
              />
            </div>

            <div>
              <h2 className="uploaded-documents-h2">
                Recently Uploaded Documents
              </h2>
              <UploadsByStudents user={user} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const StudentUploads = ({ students, user }) => {
  // Student States
  const [studentfile, setStudentfile] = useState();
  const [uploadType, setUploadType] = useState();
  const [student, setStudent] = useState();

  const handleChangeStudent = async (newFile) => {
    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", newFile);

    setStudentfile(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*
      Two fetch requests due to different headers types + content. Must run in order:
      1. addFile: uploads the document and returns the new file name
      2. postUpload: takes the file name uploaded and creates a record in the Uploads model
    */
    // Fetch 1: Upload the file to the uploads folder
    addFile(studentfile)
      .then((response) => response.json())
      .then((data) => {
        // Format the data to create a new Upload
        const upload = {
          location: data.fileName,
          display_name: data.displayName,
          type: uploadType,
          user_id: user.id,
          student_id: student,
        };

        // Fetch 2: create a new Uploads record
        postUpload(upload)
          .then((response) => response.json())
          .then((res) => {
            window.alert("File succesfully Uploaded!");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Student Container */}
      <h1>For Student View</h1>
      <div className="instructor-uploads-container">
        <form
          className="uploads-form ins-uploads-form"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <FileUploader
            handleChange={handleChangeStudent}
            name="file"
            types={fileTypes}
            classes="instructor-uploads-area"
            label="Upload Drop Files Here or click to Upload."
          />

          <div className="flexCenter mt">
            {/* Student Drop-down */}
            <label htmlFor="student" className="hidden">
              Select a student:
            </label>
            <select
              name="student"
              id="student"
              className="instructor-uploads-select"
              onChange={(e) => setStudent(e.target.value)}
              required
            >
              <option value="" hidden>
                Which Student?
              </option>
              {/* Display Student Options */}
              {students.map((student) => (
                <option key={student.student.id} value={student.student.id}>
                  {student.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flexCenter mt">
            {/* Uploads type Drop-down */}
            <label htmlFor="documents" className="hidden">
              Select an updloads type:
            </label>
            <select
              name="documents"
              id="documents"
              className="instructor-uploads-select"
              onChange={(e) => setUploadType(e.target.value)}
              required
            >
              <option value="" hidden>
                Document or Certificate?
              </option>
              <option value="Document">Document</option>
              <option value="Certificate">Certificate</option>
            </select>
          </div>

          <div className="flexCenter mt">
            <button type="submit" value="Submit" className="signup-button">
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ParoleOfficerUploads = ({ officers, students, user }) => {
  // Parole Officer States
  const [poFile, setPoFile] = useState();
  const [paroleOfficer, setParoleOfficer] = useState();

  // Student States
  const [student, setStudent] = useState();

  const handleChangePo = async (newFile) => {
    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", newFile);

    setPoFile(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*
      Two fetch requests due to different headers types + content. Must run in order:
      1. addFile: uploads the document and returns the new file name
      2. postUpload: takes the file name uploaded and creates a record in the Uploads model
    */
    // Fetch 1: Upload the file to the uploads folder
    addFile(poFile)
      .then((response) => response.json())
      .then((data) => {
        // Format the data to create a new Upload
        const upload = {
          location: data.fileName,
          display_name: data.displayName,
          type: "Document",
          student_name: student,
          user_id: user.id,
          officer_id: paroleOfficer,
        };

        // Fetch 2: create a new Uploads record
        postUpload(upload)
          .then((response) => response.json())
          .then((res) => {
            window.alert("File succesfully Uploaded!");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Parole Officer Container */}
      <h1>For Parole Officer View</h1>
      <div className="instructor-uploads-container">
        <form
          className="uploads-form ins-uploads-form"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <FileUploader
            handleChange={handleChangePo}
            name="file"
            types={fileTypes}
            classes="instructor-uploads-area"
            label="Upload Drop Files Here or click to Upload."
          />

          {/* Student Drop-down */}
          <div className="flexCenter mt">
            <select
              name="student"
              id="student"
              className="instructor-uploads-select"
              onChange={(e) => setStudent(e.target.value)}
              required
            >
              <option value="" hidden>
                Which Student?
              </option>
              {/* Display Student Options */}
              {students.map((student) => (
                <option key={student.student.id} value={student.name}>
                  {student.name}
                </option>
              ))}
            </select>
          </div>

          {/* Parole Officer Drop-down */}
          <div className="flexCenter mt">
            <label htmlFor="officer" className="hidden">
              Select a Parole Officer:
            </label>
            <select
              name="officer"
              id="officer"
              className="instructor-uploads-select"
              onChange={(e) => setParoleOfficer(e.target.value)}
              required
            >
              <option value="" hidden>
                Which Parole Officer?
              </option>
              {/* Display Parole Officer Options */}
              {officers.map((officer) => (
                <option key={officer.officer.id} value={officer.officer.id}>
                  {officer.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flexCenter mt">
            <button className="signup-button" type="submit" value="Submit">
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InstructorUploads;
