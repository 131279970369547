import React, { useState, useEffect } from "react";
import { getHomepageCourses } from "../utils/api";
import Modal from "../components/Modal";
import PartnerOrganizations from "../components/PartnerOrganizations";

const Home = (user) => {
  const [courses, setCourses] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Run Query Once
  useEffect(() => {
    // Re-set url if user gets logged out (issues with logging in if the url is not base)
    if (Object.keys(user).length === 0) {
      const url = window.location.href;
      const urlArr = url.split("/");
      if (urlArr[3]) {
        const newUrl = `${urlArr[0]}/${urlArr[1]}/${urlArr[2]}`;
        window.location.replace(newUrl);
      }
    }

    getHomepageCourses()
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        {/* Welcome Section */}
        <div className="welcome-container">
          <div className="welcome-content">
            <h1> Absolutely Powerful Classes </h1>
            <p>
              <i>Take Control of Your Life With Counselors Who Care</i>
            </p>
            <p>
              "In-Person" WebCasted Group sessions. A Certified and Accredited
              Court Program Provider
            </p>
            <div className="signup-button-container">
              <button
                className="signup-button"
                type="button"
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                <b>Sign Up Now</b>
              </button>
            </div>
          </div>
        </div>

        {/* Classes Section */}
        <section>
          <h2 style={{ fontSize: "1.5rem" }}>Classes</h2>
          <div className="classes-container">
            {/* Top Row of Classes */}
            <div className="classes--card-container"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      {/* Welcome Section */}
      <div className="welcome-container">
        <div className="welcome-content">
          <h1> Absolutely Powerful Classes </h1>
          <p>
            <i>Take Control of Your Life With Counselors Who Care</i>
          </p>
          <p>
            "In-Person" WebCasted Group sessions. A Certified and Accredited
            Court Program Provider
          </p>
          <div className="signup-button-container">
            <button
              className="signup-button"
              type="button"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              <b>Sign Up Now</b>
            </button>
          </div>
        </div>
      </div>

      {/* Classes Section */}
      <section>
        <h2 style={{ fontSize: "1.5rem" }}>Classes</h2>
        <div className="classes-container">
          {/* Top Row of Classes */}
          <div className="classes--card-container">
            {courses.map((course, index) => (
              <CourseCard course={course} key={index} />
            ))}
          </div>
          {/* Partner Organizations Section */}
        </div>
      </section>

      <PartnerOrganizations />

      {isModalOpen && (
        <Modal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      )}
    </>
  );
};

// CourseCard Component
const CourseCard = ({ course }) => {
  return (
    <div className="classes--card">
      <div className="h-50">
        <img
          src={require(`../assets/${course.image}`)}
          alt="support class"
          className="classes--card-img"
          height="200"
          width="300"
        ></img>
      </div>
      <div className="h-154 classes--card-text">
        <h2 className="classes--card-name">
          {course.name} | {course.price}
        </h2>
        <hr />
        <p className="classes--card-details">{course.description}</p>
      </div>
    </div>
  );
};

export default Home;
