import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import ActivationBtns from "../../components/ActivationBtns";
import {
  getOfficerInfo,
  getStudents,
  getOfficerRelations,
  postOfficerRelation,
  deleteOfficerRelation,
} from "../../utils/api";

const url = window.location.href;
const officer_id = url.split("/")[4];

const OfficerInfo = ({ user }) => {
  const [officer, setofficer] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [assignmentActive, setAssignmentActive] = useState(false);

  const url = window.location.href;
  const officer_id = url.split("/")[4];

  useEffect(() => {
    // Get officer Info
    getOfficerInfo(officer_id)
      .then((res) => res.json())
      .then((data) => {
        setofficer(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const displayPhoto = (image) => {
    console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/${image}`;
    }
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="grid-1X2">
              <div>
                <div className="profile-form-photo-container">
                  <img
                    className="profile-form-photo help"
                    src={displayPhoto(officer.user.image)}
                    alt="an officer"
                  />
                </div>
                <div
                  className={
                    assignmentActive
                      ? "usertype-info paroleOfficerInfo"
                      : "usertype-info"
                  }
                >
                  <div className="info-row">
                    <p className="info-label">Full Name</p>
                    <p>{officer.user.name}</p>
                  </div>
                  <div className="info-row">
                    <p className="info-label">Address</p>
                    <p className="info-address">{officer.user.address}</p>
                  </div>
                  <div className="info-row">
                    <p className="info-label">Phone Number</p>
                    <p>{officer.user.phone}</p>
                  </div>
                  <div className="info-row">
                    <p className="info-label">Email</p>
                    <p>{officer.user.email}</p>
                  </div>
                </div>

                {/* Super User Action Buttons */}
                {user.user_type === "Super User" ? (
                  <ActivationBtns userId={officer.user.id} />
                ) : (
                  <></>
                )}
              </div>

              <div>
                {/* Student and PO Assignment Page component */}
                {user.user_type === "Super User" ? (
                  <POAssignment
                    assignmentActive={assignmentActive}
                    setAssignmentActive={setAssignmentActive}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const POAssignment = ({ assignmentActive, setAssignmentActive }) => {
  return (
    <>
      {!assignmentActive ? (
        <div className="parole-officer--assignBtn-contianer">
          <button
            onClick={() => setAssignmentActive(true)}
            className="signup-button"
          >
            Assign Students
          </button>
        </div>
      ) : (
        <AssignmentComponent />
      )}
    </>
  );
};

// Student and PO Assignment Page Component -->
const AssignmentComponent = () => {
  // Set state to return students from DB
  const [students, setStudents] = useState([]);
  // Set state for search bar
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // set state for form
  const [formState, setFormState] = useState({
    assign: [],
    remove: [],
  });

  const url = window.location.href;
  const officer_id = url.split("/")[4];

  useEffect(() => {
    Promise.all([getStudents(), getOfficerRelations(officer_id)])
      .then(async (values) => {
        const studentData = await values[0].json();
        const relationsData = await values[1].json();
        const currentRelations = relationsData.map((item) => item.student_id);

        // Format Students from the db
        const studentNames = studentData.map((item) => {
          const student_id = item.student.id;
          // Check if the student is already assigned to this Parole Officer
          if (currentRelations.includes(student_id)) {
            const index = currentRelations.indexOf(student_id);

            return {
              name: item.name,
              id: student_id,
              currentlyAssigned: true,
              relation_id: relationsData[index].id,
            };
          } else {
            return {
              name: item.name,
              id: student_id,
              currentlyAssigned: false,
            };
          }
        });

        setStudents(studentNames);
      })
      .catch((err) => console.log(err));
  }, []);

  // Searchbar Functionality -->
  useEffect(() => {
    // Filter students based on search term
    const filteredStudents = students.filter((student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredStudents);
  }, [searchTerm, students]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Calculate what needs to be changed
  const calcChanges = () => {
    let sum = 0;

    if (formState.assign.length) {
      sum += 1;
    }
    if (formState.remove.length) {
      sum += 2;
    }

    return sum;
  };

  // Form Functionality -->
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(formState);

    // Sequelize will throw an error if you try to update a model with the same data
    const updates = calcChanges();

    // Handle Update
    switch (updates) {
      case 0:
        // Nothing to Update, reload page to refresh
        window.location.reload();
        break;
      case 1:
        // Only post new relations
        Promise.all(
          formState.assign.map((i) =>
            postOfficerRelation({ student_id: i, officer_id: officer_id })
          )
        )
          .then(() => {
            window.location.reload();
          })
          .catch((err) => console.log(err));
        break;
      case 2:
        // Only update relations
        Promise.all(formState.remove.map((i) => deleteOfficerRelation(i)))
          .then(() => {
            window.location.reload();
          })
          .catch((err) => console.log(err));
        break;
      default:
        // Update Both
        Promise.all([postOfficerRelation(), deleteOfficerRelation()])
          .then(() => {
            window.location.reload();
          })
          .catch((err) => console.log(err));
        break;
    }
  };

  return (
    <>
      {/*  Search box  */}
      <form className="student-search--form">
        <label htmlFor="student-search" className="hidden">
          Search for students:
        </label>
        <input
          type="search"
          id="student-search"
          name="q"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchInputChange}
          autoComplete="off"
        />
        <button className="signup-button">Search</button>
      </form>

      {/* Assignment Form */}
      <form onSubmit={handleFormSubmit}>
        <div className="student-options--form">
          <table className="student-options--form-table">
            <tbody>
              <tr className="hidden">
                <th>Class</th>
                <th>Status</th>
              </tr>

              {searchResults.map((student) => (
                <StudentRow
                  key={student.id}
                  student={student}
                  setFormState={setFormState}
                  formState={formState}
                />
              ))}
            </tbody>
          </table>
        </div>

        <button className="student-options--form-btn signup-button">
          Save Changes
        </button>
      </form>
    </>
  );
};

const StudentRow = ({ student, setFormState, formState }) => {
  const { name, id, currentlyAssigned, relation_id } = student;
  return (
    <tr>
      <td>{name}</td>
      <td>
        {currentlyAssigned ? (
          <DisabledAssignCheckbox />
        ) : (
          <AssignCheckbox
            student_id={id}
            setFormState={setFormState}
            formState={formState}
          />
        )}
        <label htmlFor="assign">Assign</label>
        {currentlyAssigned === false ? (
          <DisabledRemoveCheckbox />
        ) : (
          <RemoveCheckbox
            relation_id={relation_id}
            setFormState={setFormState}
            formState={formState}
          />
        )}
        <label htmlFor="remove">Remove</label>
        <br />
      </td>
    </tr>
  );
};

// Checkbox components --->
const AssignCheckbox = ({ student_id, setFormState, formState }) => {
  const [isChecked, setIsChecked] = useState(true);

  const handleChange = () => {
    setIsChecked(!isChecked);
    const arr = formState.assign;

    if (isChecked) {
      arr.push(student_id); // Add to Form State
      setFormState({ ...formState, assign: arr });
    } else {
      const index = arr.indexOf(student_id);
      arr.splice(index, 1); // Remove from Form State
      setFormState({ ...formState, assign: arr });
    }
  };

  return (
    <input
      type="checkbox"
      id="assign"
      value={isChecked}
      onChange={handleChange}
    />
  );
};

const RemoveCheckbox = ({ relation_id, setFormState, formState }) => {
  const [isChecked, setIsChecked] = useState(true);

  const handleChange = () => {
    setIsChecked(!isChecked);
    const arr = formState.remove;

    if (isChecked) {
      arr.push(relation_id); // Add to Form State
      setFormState({ ...formState, remove: arr });
    } else {
      const index = arr.indexOf(relation_id);
      arr.splice(index, 1); // Remove from Form State
      setFormState({ ...formState, remove: arr });
    }
  };

  return (
    <input
      type="checkbox"
      id="remove"
      value={isChecked}
      onChange={handleChange}
    />
  );
};

const DisabledAssignCheckbox = () => {
  return <input type="checkbox" id="assign" value="assign" disabled />;
};

const DisabledRemoveCheckbox = () => {
  return <input type="checkbox" id="remove" value="remove" disabled />;
};

export default OfficerInfo;
