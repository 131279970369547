import React from "react";
import DashboardNav from "../components/DashboardNav";
import certIcon from "../assets/cert-icon.webp";

// Import of certificates
import pdf1 from "../assets/InstructorCertificates/Domestic-Violence-AngelaGonzales.pdf";
import pdf2 from "../assets/InstructorCertificates/child-safety-resources.pdf";
import pdf3 from "../assets/InstructorCertificates/IntroToHumanTrafficking.pdf";
import pdf4 from "../assets/InstructorCertificates/parentinstructor.pdf";
import pdf5 from "../assets/InstructorCertificates/batters-intervention-Angela.pdf";
import pdf6 from "../assets/InstructorCertificates/dv-assessAndTreat-Angela.pdf";
import pdf7 from "../assets/InstructorCertificates/child-abuse-angela.pdf";
import pdf8 from "../assets/InstructorCertificates/csec-angela.pdf";
import pdf9 from "../assets/InstructorCertificates/childhood-experiences-angela.pdf";
import pdf10 from "../assets/InstructorCertificates/lethal-angela.pdf";
import pdf11 from "../assets/InstructorCertificates/prevention-angela.pdf";
import pdf12 from "../assets/InstructorCertificates/Edward-Abasta-Domestic-Partner-Violence.pdf";
import pdf13 from "../assets/InstructorCertificates/DV-Facilitator-Certificate-Edward-Abasta-Fundamentals-Group-Dynamics.pdf";
import pdf14 from "../assets/InstructorCertificates/CAARR-Facilitator-Certificate-Edward-Abasta-2006.pdf";
import pdf15 from "../assets/InstructorCertificates/ACEScodependcy.pdf";
import pdf16 from "../assets/InstructorCertificates/Anger_Irritation_and_Resentment_Clear_the_AIR_2_Hours.pdf";
import pdf17 from "../assets/InstructorCertificates/Anger_Management.pdf";
import pdf18 from "../assets/InstructorCertificates/AssessingDangerous.pdf";
import pdf19 from "../assets/InstructorCertificates/Assessment_and_Screening_Intro.pdf";
import pdf20 from "../assets/InstructorCertificates/Domestic_Violence_5_Hours.pdf";
import pdf21 from "../assets/InstructorCertificates/Positive_Parenting_6_Hours.pdf";
import pdf22 from "../assets/InstructorCertificates/Rape_Trauma_2_Hours.pdf";
import pdf23 from "../assets/InstructorCertificates/Understanding_Autism_Spectrum_Disorders_2_Hours.pdf";

const ProviderCertificates = ({ user }) => {
  // Certificate Objects to loop through
  const certificates = [
    {
      id: 4,
      name: "Parent Instructor Certification",
      linkName: "National Parent Instructor Association",
      file: pdf4,
    },
    {
      id: 1,
      name: "Domestic Violence Counselor Training Certification",
      linkName: "Casa De La Familia Human Trafficking Provider Training(CSEC)",
      file: pdf1,
    },
    {
      id: 2,
      name: "Child Safety Resources Certification",
      linkName: "National Center for Missing & Exploited Children",
      file: pdf2,
    },
    {
      id: 3,
      name: "Introduction to Child Sex Trafficking",
      linkName: "National Center for Missing & Exploited Children",
      file: pdf3,
    },
    {
      id: 5,
      name: "Creating a Process of Change for Men Who Batter: Comprehensive Training Certification",
      linkName: "Domestic Abuse Intervention Programs",
      file: pdf5,
    },
    {
      id: 6,
      name: "Certified Batterer Intervention Assessor/Facilitator",
      linkName: "Domestic Violence Treament Providers Program",
      file: pdf6,
    },
    {
      id: 7,
      name: "California Child Abuse Reporter Online Training Certificate",
      linkName: "California Department of Social Services",
      file: pdf7,
    },
    {
      id: 8,
      name: "CSEC Training: Intro to Aces",
      linkName: "Chlidren's Fund CSEC Training Program",
      file: pdf8,
    },
    {
      id: 9,
      name: "Preventing Adverse Childhood Experiences",
      linkName: "Centers for Disease Control and Prevention (CDC)",
      file: pdf9,
    },
    {
      id: 10,
      name: "Counseling on Access to Lethal Means Certificate",
      linkName:
        "ZERO Suicide Institute Counseling on Access to Lethal Means (CALM) Program",
      file: pdf10,
    },
    {
      id: 11,
      name: "Principles of Prevention",
      linkName: "Centers for Disease Control and Prevention (CDC)",
      file: pdf11,
    },
    {
      id: 12,
      name: "Domestic Violence Curriculum Certificate",
      linkName: "ALLCEUS National Approval (2015)",
      file: pdf12,
    },
    {
      id: 13,
      name: "Association of Batterers' Intervention Programs",
      linkName: "Fundamentals of Group Dynamics",
      file: pdf13,
    },
    {
      id: 14,
      name: "CAARR Institute",
      linkName: "Diploma Social Model Studies",
      file: pdf14,
    },
    {
      id: 15,
      name: "ACEs, Abandonment, Codependency and Attachment Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf15,
    },
    {
      id: 16,
      name: "Anger, Irritation and Resentment Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf16,
    },
    {
      id: 17,
      name: "Anger Management Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf17,
    },
    {
      id: 18,
      name: "Assessing Dangerousness and Abuse for the NCMHCE Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf18,
    },
    {
      id: 19,
      name: "Assessment and Screening Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf19,
    },
    {
      id: 20,
      name: "Domestic Violence Curriculum Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf20,
    },
    {
      id: 21,
      name: "Positive Parenting Curriculum Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf21,
    },
    {
      id: 22,
      name: "Rape Trauma Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf22,
    },
    {
      id: 23,
      name: "Understanding Autism Spectrum Disorders Certificate",
      linkName: "ALLCEUS National Approval (2023)",
      file: pdf23,
    },
  ];

  const CertificateDisplay = ({ certificate }) => {
    const downloadCertificate = () => {
      const pdf = certificate.file;
      window.open(pdf, "_blank");
    };

    return (
      <div className="cert-container">
        <div className="cert-title">
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "20px" }}
          >
            gavel
          </span>
          <p>
            <b>{certificate.name}</b>
          </p>
          <span>
            <img
              src={certIcon}
              alt="certificate-icon"
              style={{ width: "25px" }}
            ></img>
          </span>
        </div>
        <div className="cert-link-container">
          <p className="cert-link" onClick={downloadCertificate}>
            {certificate.linkName}
          </p>
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "20px" }}
          >
            description
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h1>Facilitator Provider Certificates</h1>

            {/* The certificates */}
            {certificates.map((certificate) => (
              <CertificateDisplay
                key={certificate.id}
                certificate={certificate}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
export default ProviderCertificates;
