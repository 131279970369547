import React from "react";
import DashboardNav from "../../components/DashboardNav";
import { postUpload, addFile } from "../../utils/api";
import { FileUploader } from "react-drag-drop-files";

// Allowed types of files for upload
const fileTypes = ["JPG", "PNG", "PDF"];

const Uploads = ({ user }) => {
  /*
    Two fetch requests due to different headers types + content. Must run in order:
    1. addFile: uploads the profile image and returns the new file name
    2. postUpload: takes the file name uploaded and creates a record in the Uploads model
  */
  const handleChange = async (newFile) => {
    console.log(newFile);
    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", newFile);

    // Fetch 1: Upload the file to the uploads folder
    addFile(formData)
      .then((response) => response.json())
      .then((data) => {
        // Format the data to create a new Upload
        const upload = {
          location: data.fileName,
          display_name: data.displayName,
          type: "Document",
          user_id: user.id,
          student_id: user.student_id,
        };

        // Fetch 2: create a new Uploads record
        postUpload(upload)
          .then((response) => response.json())
          .then((res) => {
            window.alert("File succesfully Uploaded!");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Uploads Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <form className="uploads-form" encType="multipart/form-data">
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                classes="test-input"
                label="Upload or Drop Files Here or click below to Upload."
              />
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Uploads;
