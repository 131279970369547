import React from "react";
import DashboardNav from "../components/DashboardNav";
import groupRulesPdf from "../assets/Group-Rules.pdf";

const GroupRules = ({ user }) => {
  const downloadGroupRules = () => {
    window.open(groupRulesPdf, "_blank");
  };

  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h1>Absolutely Powerful Classes Group Rules</h1>

            {/* The rules */}
            <ol className="group-rules-ol">
              <li>
                I agree to abide by all rules, guidelines, policies and
                procedures of Absolutely Powerful Classes (APC) and the
                suggestions of my counselors.
              </li>
              <li>
                I agree to abstain from alcohol and other mood altering
                substances while I am doing the Program. I will do my best to
                avoid high-risk situations (people and places that may make it
                harder to gain knowledge of the tools that are provided in this
                Program).
              </li>
              <li>
                I agree to discuss all prescribed medications I may be taking
                with the staff.
              </li>
              <li>
                I understand that if I bring alcohol or drugs onto this
                facility, I will be asked to leave immediately and may not be
                allowed to complete the Program.
              </li>
              <li>
                I agree to be responsible for attending the prescribed sessions
                and at their prescribed frequency. I agree to be on time, and to
                stay for each entire session. I understand that a staff member
                must approve in advance circumstances that might involve
                arriving late, leaving early, or preventing me from attending.
              </li>
              <li>
                If I am using a cell phone, tablet or any other mobile video
                device during LIVE GROUPS, I agree to be in a fixed location. NO
                moving around, NO distractions or I will not be credited for my
                participation.
              </li>
              <li>
                I understand that once the group has started I need to stay in
                that group. There is no eating during group. I can bring a drink
                (water, coffee, tea only) in at the start of group. I agree to
                use the restroom before group so that I do not have to leave
                group for the bathroom, unless I have informed the staff in
                advance of a related medical condition.
              </li>
              <li>
                I agree to maintain the anonymity and confidentiality of all
                other participants in the program. What others say in group is
                the property of group only.
              </li>
              <li>
                I understand the need to attend all sessions. I also agree to
                discuss with staff any resistance I may have to attending groups
                and that I am to actively participate in my own treatment.
              </li>
              <li>
                I agree to honor my financial obligation to APC if applicable.
              </li>
              <li>
                I understand that staff is mandated by both state and federal
                law to report any ongoing suspected child abuse, elderly abuse
                or other dependent adult abuse.
              </li>
              <li>
                I understand that APC staff is not to accept gifts from clients
                or their families.
              </li>
              <li>
                I will not enter into any romantic and/or sexual relationships
                with staff. I have also been advised to refrain from entering
                into any romantic and/or sexual relationship with any
                participant of the program.
              </li>
              <li>
                I understand that if I do not follow all of the Program rules
                and regulations under Absolutely Powerful Classes agreement,
                that they may be reasons for my termination.
              </li>
              <li>
                I understand that I must be clothed during group session. I
                understand that I must refrain from driving while in group
                session. I understand that I must refrain from all distractions
                including but not limited to, cooking, combing of hair, and
                putting on make up.
              </li>
            </ol>

            {/* Download PDF Copy button */}
            <button onClick={downloadGroupRules} className="intake-form-btn">
              Download Group Rules PDF
            </button>
          </div>
        </div>
      </section>
    </>
  );
};
export default GroupRules;
