import React from "react";

const About = () => {
  return (
    <section>
      <h1 className="about_h1"> About Us </h1>
      <div className="about_content">
        <img
          className="about_image"
          src="https://i.postimg.cc/2yMxpzL4/chang-duong-Sj0i-Mtq-Z4w-unsplash.jpg"
          alt="A group of peope standing in the sunset."
        />
        <p className="about_paragraph">
          Starting at only $10, Absolutely Powerful Classes is your gateway to
          affordable, user friendly, online courses that satisfy court ordered
          "In-Person" program requirements in California. Our pricing is simple
          with no hidden fees of any kind and a 100% money-back guarantee if
          your certificate is not accepted for absolutely any reason. Absolutely
          Powerful Classes is a Certified and Accredited Court Programs
          Provider. Our WebCasted group sessions provide "In-Person" program
          requirements. Absolutely Powerful Classes has over 22 years of
          experience in counseling and facilitation. With over 80 hours of
          comprehensive classes offered, the programs include in-depth
          information, expansive content, detailed curriculum, and the highest
          level of security in the industry.
        </p>
      </div>
    </section>
  );
};

export default About;
