import React, { useState, useEffect } from "react";
import { getResponse, putResponse } from "../../utils/api";
import DashboardNav from "../../components/DashboardNav";
import { useNavigate } from "react-router-dom";

const InstructorFeedback = ({ user }) => {
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const [isLoading, setLoading] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [stu_response, setStudentResponse] = useState("");

  const url = window.location.href;
  const id = url.split("/")[4];

  // Run Query Once
  useEffect(() => {
    if (stu_response.length > 240) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [stu_response]);

  // Run Query Once
  useEffect(() => {
    getResponse(id)
      .then((res) => res.json())
      .then((data) => {
        setResponse(data);
        if (data.stu_response) {
          setStudentResponse(data.stu_response);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    putResponse(id, {
      stu_response: stu_response,
      status: "Submitted",
    })
      .then((res) => res.json())
      .then((data) => {
        // re-route to the Classes Page
        navigate(`/classes`);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Lesson Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h1>{response.lesson.title}</h1>
            <p>{response.lesson.content}</p>

            <p>
              <b>Instructor's Notes:</b>
            </p>
            <p>{response.ins_feedback}</p>

            <form onSubmit={handleFormSubmit}>
              <label htmlFor="assignment">Response:</label>
              <br></br>
              <textarea
                id="assignment"
                name="assignment"
                value={stu_response}
                placeholder="Write your response here"
                onChange={(e) => setStudentResponse(e.target.value)}
              ></textarea>
              <br></br>
              <p>{stu_response.length} / 240 </p>
              <br></br>

              <div className="assessment--btn_container">
                <button
                  className="back-bnt"
                  onClick={() => navigate(`/classes`)}
                >
                  Back
                </button>
                {canSubmit === false ? (
                  <input
                    className="signup--form--button lesson_btn disabled"
                    type="submit"
                    value="Submit"
                    disabled
                  />
                ) : (
                  <input
                    className="signup--form--button lesson_btn"
                    type="submit"
                    value="Submit"
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default InstructorFeedback;
