import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import ActivationBtns from "../../components/ActivationBtns";
import { getInstructorInfo } from "../../utils/api";

const InstructorInfo = ({ user }) => {
  const [instructor, setInstructor] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const url = window.location.href;
  const instructor_id = url.split("/")[4];

  useEffect(() => {
    // Get instructor Info
    getInstructorInfo(instructor_id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setInstructor(data);
        setSessions(data.sessions);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  // SessionRow Component
  const SessionRow = ({ session }) => {
    return (
      <tr>
        <td className="bg-fff">{session.course.name}</td>
        <td className="bg-fff">
          {session.start_date}-{session.end_date}
        </td>
      </tr>
    );
  };

  const displayPhoto = (image) => {
    console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/${image}`;
    }
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }
  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="grid-1X2">
              <div>
                <div className="profile-form-photo-container">
                  <img
                    className="profile-form-photo help"
                    src={displayPhoto(instructor.user.image)}
                    alt="a instructor"
                  />
                </div>
                <div className="usertype-info">
                  <div className="info-row">
                    <p className="info-label">Full Name</p>
                    <p>{instructor.user.name}</p>
                  </div>
                  <div className="info-row">
                    <p className="info-label">Address</p>
                    <p className="info-address">{instructor.user.address}</p>
                  </div>
                  <div className="info-row">
                    <p className="info-label">Phone Number</p>
                    <p>{instructor.user.phone}</p>
                  </div>
                  <div className="info-row">
                    <p className="info-label">Email</p>
                    <p>{instructor.user.email}</p>
                  </div>
                  <div className="info-row">
                    <p className="info-label">Birth Date</p>
                    <p>{instructor.birthday}</p>
                  </div>
                </div>

                {/* Super User Action Buttons */}
                {user.user_type === "Super User" ? (
                  <ActivationBtns userId={instructor.user.id} />
                ) : (
                  <></>
                )}
              </div>

              <div>
                {/* <!-- Sessions Table --> */}
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <th>Sessions</th>
                        <th>Dates</th>
                      </tr>
                      {!sessions[0] ? (
                        <>
                          <tr>
                            <td className="color-muted">
                              No Sessions Associated
                            </td>
                            <td></td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {sessions.map((session) => (
                            <SessionRow key={session.id} session={session} />
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InstructorInfo;
