import React, { useState, useEffect } from "react";
import { getSessions } from "../../utils/api";
import DashboardNav from "../../components/DashboardNav";
import { useNavigate } from "react-router-dom";

const Catalog = ({ user }) => {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState();
  const [isLoading, setLoading] = useState(true);

  // Run Query Once
  useEffect(() => {
    getSessions()
      .then((res) => res.json())
      .then((data) => {
        setSessions(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Catalog Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h2 className="catalog--h2">Available Group Sessions</h2>

            {!sessions[0] ? (
              <>
                <div className="catalog-placeholder-text">
                  <p>
                    We don’t have any upcoming sessions scheduled at the moment,
                    but don’t worry - we’ll be adding more soon! Be sure to
                    check back with us regularly for updates. If you have any
                    questions or concerns, please feel free to contact us via
                    email at <b>admin@absolutelypowerfulclasses.com</b>
                  </p>
                </div>
              </>
            ) : (
              <>
                {/* Loop through and display session info */}
                <div className="class-card--container">
                  {sessions.map((session) => (
                    <CatalogCard
                      key={session.id}
                      session={session}
                      navigate={navigate}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

// Catalog Card Component
const CatalogCard = ({ session, navigate }) => {
  return (
    <div className="class-card">
      <h3>{session.course.name}</h3>
      <p>
        {session.start_date} - {session.end_date}
      </p>
      <hr className="class-card--hr"></hr>
      <p className="class-card-description">{session.course.description}</p>
      <button
        className="class-card--btn"
        id={session.id}
        onClick={(e) => {
          navigate(`/class-registration/${e.target.id}`);
        }}
      >
        Enroll
      </button>
    </div>
  );
};

export default Catalog;
