import React from "react";

const ClientClassEnrollment = ({ isModalOpen, setIsModalOpen, student }) => {
  return (
    <>
      <div className="modal--fixed">
        <div className="class-enrollment--container">
          <button
            className="close-btn"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            X
          </button>

          {/* Enrollment Table */}
          <h1>{student.name}</h1>
          <table>
            <thead>
              <tr>
                <th>Class</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {student.sessions.map((session) => (
                <ClassRow key={session.id} session={session} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const ClassRow = ({ session }) => {
  return (
    <tr>
      <td>{session.course.name}</td>
      <td>{session.roster.status}</td>
    </tr>
  );
};

export default ClientClassEnrollment;
