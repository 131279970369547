import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import ActivationBtns from "../../components/ActivationBtns";
import { getStudentInfo, postNotes } from "../../utils/api";
import StudentProgressModal from "../../components/StudentProgressModal";

const StudentInfo = ({ user }) => {
  const [student, setStudent] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [formState, setFormState] = useState({
    content: "",
  });
  const [canSubmit, setCanSubmit] = useState(false);
  // Student Progress State and Modal
  const [isModalOpen, setisModalOpen] = useState(false);
  const [selectedSession, setselectedSession] = useState({});
  const openModal = (session) => {
    setselectedSession(session);
    setisModalOpen(true);
  };

  // Run Query Once
  useEffect(() => {
    if (formState.content.length <= 255) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [formState]);

  const url = window.location.href;
  const student_id = url.split("/")[4];

  useEffect(() => {
    // Get Student Info
    getStudentInfo(student_id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setStudent(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  // PastClasses Component
  const PastClasses = ({ session }) => {
    return (
      <tr>
        <td>{session.course.name}</td>
        <td>
          {session.roster.status} ({session.start_date} - {session.end_date})
          {user.user_type !== "Parole Officer" ? (
            <>
              {" "}
              <br></br>
              <button
                className="view-details-btn"
                onClick={() => openModal(session)}
              >
                View Details
              </button>
            </>
          ) : (
            <></>
          )}
        </td>
      </tr>
    );
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Gather Note Data to Post new note
    const noteData = {
      content: formState.content,
      user_id: user.id,
      student_id: student.id,
    };

    // Get Student Info
    postNotes(noteData)
      .then((res) => res.json())
      .then((data) => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const displayPhoto = (image) => {
    // console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/${image}`;
    }
  };

  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="grid-1X2 mb-30">
              <div>
                <div className="profile-form-photo-container">
                  <img
                    className="profile-form-photo help"
                    src={displayPhoto(student.user.image)}
                    alt="a student"
                  />
                </div>
                <div className="usertype-info">
                  <div className="info-row">
                    <p className="info-label">Full Name</p>
                    <p>{student.user.name}</p>
                  </div>
                  {user.user_type !== "Parole Officer" ? (
                    <div className="info-row">
                      <p className="info-label">Address</p>
                      <p className="info-address">{student.user.address}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="info-row">
                    <p className="info-label">Phone Number</p>
                    <p>{student.user.phone}</p>
                  </div>
                  <div className="info-row">
                    <p className="info-label">Email</p>
                    <p>{student.user.email}</p>
                  </div>
                  {user.user_type !== "Parole Officer" ? (
                    <div className="info-row">
                      <p className="info-label">Birth Date</p>
                      <p>{student.birthday}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {user.user_type !== "Parole Officer" ? (
                    <>
                      <div className="info-row">
                        <p className="info-label">Probation Officer</p>
                        <p>
                          {student.parole_officer
                            ? student.parole_officer
                            : "---"}
                        </p>
                      </div>
                      <div className="info-row">
                        <p className="info-label">Court Case</p>
                        <p>{student.court_case ? student.court_case : "---"}</p>
                      </div>
                      <div className="info-row">
                        <p className="info-label">Judge</p>
                        <p>{student.judge ? student.judge : "---"}</p>
                      </div>
                      <div className="info-row">
                        <p className="info-label">Attorney Name</p>
                        <p>{student.att_name ? student.att_name : "---"}</p>
                      </div>
                      <div className="info-row">
                        <p className="info-label">Attorney Email</p>
                        <p>{student.att_email ? student.att_email : "---"}</p>
                      </div>
                      <div className="info-row">
                        <p className="info-label">Attorney Phone</p>
                        <p>{student.att_phone ? student.att_phone : "---"}</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* Super User Action Buttons */}
                {user.user_type === "Super User" ? (
                  <ActivationBtns userId={student.user.id} />
                ) : (
                  <></>
                )}
              </div>

              <div>
                {/* <!-- Past Classes Table --> */}
                <div>
                  <h2>Past Classes</h2>
                  <table>
                    <tbody>
                      <tr>
                        <th>Class</th>
                        <th>Status</th>
                      </tr>
                      {!student.sessions[0] ? (
                        <>
                          <tr>
                            <td className="color-muted">
                              Currently Not Enrolled
                            </td>
                            <td></td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {student.sessions.map((session) => (
                            <PastClasses key={session.id} session={session} />
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div style={{ padding: "20px" }}>
              <hr></hr>
              {user.user_type !== "Parole Officer" ? (
                <>
                  <p style={{ textAlign: "center", fontWeight: "700" }}>
                    Notes
                  </p>
                  <div>
                    {student.notes.map((note) => (
                      <div key={note.id}>
                        <p>{note.content}</p>
                        <p className="text-small">
                          - {note.user.name} {note.date}
                        </p>
                      </div>
                    ))}
                  </div>

                  <hr></hr>

                  <p className="info-label">Add Notes</p>
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="notes" className="hidden">
                      Note
                    </label>
                    <textarea
                      className="attendance-notes"
                      id="content"
                      name="content"
                      value={formState.content}
                      onChange={handleInputChange}
                    />
                    <p>{formState.content.length} / 255 </p>
                    {canSubmit === false ? (
                      <button
                        type="submit"
                        disabled
                        className="intake_button"
                        style={{ backgroundColor: "#1c4d7c9e" }}
                      >
                        Submit
                      </button>
                    ) : (
                      <button type="submit" className="intake_button">
                        Submit
                      </button>
                    )}
                  </form>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
      {isModalOpen && (
        <StudentProgressModal
          selectedSession={selectedSession}
          setisModalOpen={setisModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
    </>
  );
};

export default StudentInfo;
