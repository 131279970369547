import React from "react";
import { Link } from "react-router-dom";
import Auth from "../../utils/auth";

const DashboardNavMobileLinks = ({ user, toggleMenu }) => {
  const isEnrolled = user.isEnrolled;

  // Conditionally render Nav links based on the user_type
  const UserTypeNavLinks = () => {
    switch (user.user_type) {
      case "Student":
        return studentLinks;
      case "Parole Officer":
        return officerLinks;
      case "Instructor":
        return instructorLinks;
      case "Super User":
        return superUserLinks;
      default:
        return null;
    }
  };

  const studentLinks = (
    <>
      {isEnrolled === true ? (
        <li className="mobile_dashboard--menu-li">
          <Link
            onClick={() => toggleMenu()}
            to={"/classes"}
            className="dashboard--menuItem"
          >
            <b>Your Classes</b>
          </Link>
        </li>
      ) : null}
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/catalog"}
          className="dashboard--menuItem"
        >
          <b>Catalog</b>
        </Link>
      </li>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/uploads"}
          className="dashboard--menuItem"
        >
          <b>Uploads</b>
        </Link>
      </li>
      {isEnrolled === true ? (
        <>
          <li className="mobile_dashboard--menu-li">
            <Link
              onClick={() => toggleMenu()}
              to={"/certificates"}
              className="dashboard--menuItem"
            >
              <b>Certificates</b>
            </Link>
          </li>
          <li className="mobile_dashboard--menu-li">
            <Link
              onClick={() => toggleMenu()}
              to={"/documents"}
              className="dashboard--menuItem"
            >
              <b>Documents</b>
            </Link>
          </li>
        </>
      ) : null}
    </>
  );

  const officerLinks = (
    <>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/dashboard"}
          className="dashboard--menuItem"
        >
          <b>Current Clients</b>
        </Link>
      </li>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/documents"}
          className="dashboard--menuItem"
        >
          <b>Documents</b>
        </Link>
      </li>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/pastClients"}
          className="dashboard--menuItem"
        >
          <b>Past Clients</b>
        </Link>
      </li>
    </>
  );

  const instructorLinks =
    user.is_approved === true ? (
      <>
        <li className="mobile_dashboard--menu-li">
          <Link
            onClick={() => toggleMenu()}
            to={"/dashboard"}
            className="dashboard--menuItem"
          >
            <b>Dashboard</b>
          </Link>
        </li>
        <li className="mobile_dashboard--menu-li">
          <Link
            onClick={() => toggleMenu()}
            to={"/upcoming-sessions"}
            className="dashboard--menuItem"
          >
            <b>Upcoming Sessions</b>
          </Link>
        </li>
        <li className="mobile_dashboard--menu-li">
          <Link
            onClick={() => toggleMenu()}
            to={"/past-sessions"}
            className="dashboard--menuItem"
          >
            <b>Past Sessions</b>
          </Link>
        </li>
        <li className="mobile_dashboard--menu-li">
          <Link
            onClick={() => toggleMenu()}
            to={"/students"}
            className="dashboard--menuItem"
          >
            <b>Students</b>
          </Link>
        </li>
        <li className="mobile_dashboard--menu-li">
          <Link
            onClick={() => toggleMenu()}
            to={"/uploads"}
            className="dashboard--menuItem"
          >
            <b>Uploads</b>
          </Link>
        </li>
      </>
    ) : null;

  const superUserLinks = (
    <>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/dashboard"}
          className="dashboard--menuItem"
        >
          <b>Dashboard</b>
        </Link>
      </li>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/upcoming-sessions"}
          className="dashboard--menuItem"
        >
          <b>Upcoming Sessions</b>
        </Link>
      </li>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/past-sessions"}
          className="dashboard--menuItem"
        >
          <b>Past Sessions</b>
        </Link>
      </li>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/students"}
          className="dashboard--menuItem"
        >
          <b>Students</b>
        </Link>
      </li>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/uploads"}
          className="dashboard--menuItem"
        >
          <b>Uploads</b>
        </Link>
      </li>
      <li className="mobile_dashboard--menu-li">
        <Link
          onClick={() => toggleMenu()}
          to={"/lessons"}
          className="dashboard--menuItem"
        >
          <b>Lessons</b>
        </Link>
      </li>
    </>
  );

  return (
    <div className="mobile-navigation">
      {/* Name */}
      <section className="dashboard-heading">
        <div>
          <h1 className="dashboard--h1">{user.name}</h1>
        </div>
      </section>
      {/* Menu */}
      <section>
        <div className="dashboard--navbar">
          <ul className="dashboard--menu">{UserTypeNavLinks()}</ul>
        </div>
      </section>
      {/* Temporary Placement of Logout Button */}
      <div style={{ margin: "20px " }} className="logout_center">
        <button className="mobile-logout" onClick={() => Auth.logout()}>
          Log Out
        </button>
      </div>
    </div>
  );
};

export default DashboardNavMobileLinks;
