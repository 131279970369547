import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import { useNavigate } from "react-router-dom";
import {
  getLessons,
  removeLesson,
  getLessonOrder,
  postLessonOrder,
  updateLessonOrder,
} from "../../utils/api";
import { List, arrayMove } from "react-movable";

const Lessons = ({ user }) => {
  const navigate = useNavigate();
  const [lessons, setLessons] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [lessonOrderId, setLessonOrderId] = useState();
  const [displayLessons, setDisplayLessons] = useState([]);

  useEffect(() => {
    getLessons()
      .then((res) => res.json())
      .then((lessonData) => {
        setLessons(lessonData);
        getLessonOrder()
          .then((res) => res.json())
          .then((data) => {
            if (data[0]) {
              setLessonOrderId(data[0].id);

              const dbLessonOrder = JSON.parse(data[0].content);
              const lessonOrder = [];
              for (let i = 0; i < dbLessonOrder.length; i++) {
                // Use the id's from the LessonOrder model to order the lessons for display
                const index = lessonData.findIndex(
                  (l) => l.id === dbLessonOrder[i]
                );
                lessonOrder.push(lessonData[index]);
              }

              setDisplayLessons(lessonOrder);
            } else if (!data[0] && lessons) {
              // If the lesson order doesn't exist, use the lessons from the db to create the order
              const lessonIds = lessonData.map((lesson) => lesson.id);
              const lessonOrderData = { content: JSON.stringify(lessonIds) };

              postLessonOrder(lessonOrderData).then(() =>
                window.location.reload()
              );
            } else {
              return;
            }

            setLoading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const handleLessonDelete = (id) => {
    removeLesson(id)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  // Function to handle item movement in the list
  const handleItemMove = ({ oldIndex, newIndex }) => {
    const reorderedLessons = arrayMove(displayLessons, oldIndex, newIndex);
    setDisplayLessons(reorderedLessons);

    // Update the lesson order in the database
    const lessonIds = reorderedLessons.map((lesson) => lesson.id);
    const lessonOrderData = { content: JSON.stringify(lessonIds) };
    // Function to save new Lesson order

    updateLessonOrder(lessonOrderData, lessonOrderId).catch((err) =>
      // If the lesson order doesn't exist, use the lessons from the db to create the order

      console.log(err)
    );
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      <section>
        <div className="lessons--heading">
          <h1 className="lessons--h1">Lessons</h1>
          <button
            className="createbtn"
            onClick={() => navigate("/lessons/create")}
          >
            Create
          </button>
        </div>
      </section>

      {/* Lessons Table*/}
      <section style={{ minHeight: "30em" }}>
        <table>
          <thead>
            <tr>
              <th>Lessons</th>
              <th>Actions</th>
            </tr>
          </thead>
          <List
            values={displayLessons}
            onChange={handleItemMove}
            renderList={({ children, props }) => (
              <tbody {...props}>{children}</tbody>
            )}
            renderItem={({ value, props }) => (
              <tr key={value.id} {...props}>
                <td>{value.title}</td>
                <td>
                  <a href={`/lessons/edit/${value.id}`}>Edit</a> |
                  <button
                    onClick={() => handleLessonDelete(value.id)}
                    className="link--btn"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            )}
          />
        </table>
      </section>
    </>
  );
};

export default Lessons;
