import React, { useState } from "react";
import Modal from "../Modal";
import { reroute } from "../../utils/reroute";
import { Link, useNavigate } from "react-router-dom";
import DashboardNavMobileLinks from "../DashboardNavMobileLinks";

const Header = ({ user }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleMenu() {
    const menu = document.querySelector(".menu");
    const closeIcon = document.querySelector(".closeIcon");
    const menuIcon = document.querySelector(".menuIcon");

    if (menu.classList.contains("showMenu")) {
      menu.classList.remove("showMenu");
      closeIcon.style.display = "none";
      menuIcon.style.display = "block";
    } else {
      menu.classList.add("showMenu");
      closeIcon.style.display = "block";
      menuIcon.style.display = "none";
    }
  }

  return (
    <>
      <header>
        <div className="header">
          <div>
            {/* {user !== undefined ? (
              <img
                onClick={() => reroute(user.user_type)}
                className="logo"
                alt="Absolutely Powerful Logo"
                src={require(`../../assets/Absolutely-Powerful-Classes-4.webp`)}
              />
            ) : ( */}
            <Link to={"/"}>
              <img
                className="logo"
                alt="Absolutely Powerful Logo"
                src={require(`../../assets/Absolutely-Powerful-Classes-4.webp`)}
              />
            </Link>
            {/* )} */}
          </div>
          {/* <!-- Main Menu --> */}
          <ul className="menu">
            {/* Check if a user is logged in */}
            {user !== undefined ? (
              <>
                <div className="mobile-nav-container">
                  <div className="profile_picture-container">
                    <img
                      onClick={() => {
                        toggleMenu();
                        navigate("/profile");
                      }}
                      className="profile_picture"
                      src={
                        user.image && user.image !== null
                          ? `${user.image}`
                          : "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png"
                      }
                      alt="a student"
                    />
                  </div>
                  <DashboardNavMobileLinks
                    user={user}
                    toggleMenu={toggleMenu}
                  />
                </div>
              </>
            ) : (
              <>
                <li onClick={() => setIsModalOpen(!isModalOpen)}>
                  <a
                    className="menuItem Login"
                    style={{ textDecoration: "none" }}
                    href="#login"
                    onClick={() => toggleMenu()}
                  >
                    <b>Login</b>
                  </a>
                </li>
                <li>
                  <Link
                    className="menuItem"
                    style={{ textDecoration: "none" }}
                    to={"/about-us"}
                    onClick={() => toggleMenu()}
                  >
                    <b>About Us</b>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <button className="hamburger">
          <i className="menuIcon material-icons" onClick={() => toggleMenu()}>
            menu
          </i>
          <i className="closeIcon material-icons" onClick={() => toggleMenu()}>
            close
          </i>
        </button>
      </header>

      {isModalOpen && (
        <Modal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      )}
    </>
  );
};

export default Header;
