// Token stores the user's name, email, and type
import decode from "jwt-decode";

// Route the user to their "dashboard" based on user_type
export const reroute = (user_type) => {
  switch (user_type) {
    case "Student":
      const isEnrolled = checkIsEnrolled();
      handleStudent(isEnrolled);
      return;
    case "Parole Officer":
      window.location.assign("/dashboard");
      return;
    case "Instructor":
      window.location.assign("/dashboard");
      return;
    case "Super User":
      window.location.assign("/dashboard");
      return;
    default:
      return null;
  }
};

export const checkIsEnrolled = () => {
  const token = decode(localStorage.getItem("apc_session"));
  // Get isEnrolled boolean from token
  return token.data.isEnrolled;
};

const handleStudent = (isEnrolled) => {
  if (isEnrolled === true) {
    window.location.assign("/classes");
  } else {
    window.location.assign("/catalog");
  }
};
