import React, { useState, useEffect } from "react";
import { getAllDocuments } from "../../utils/api";

const AllUploads = () => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // Run Query Once
  useEffect(() => {
    getAllDocuments()
      .then((res) => res.json())
      .then((data) => {
        // Check if data is an array and has some items
        if (Array.isArray(data) && data.length > 0) {
          const allUploads = data.flatMap((user) => {
            if (user.uploads && user.uploads.length > 0) {
              // Attach user's name and user_type to each document
              return user.uploads.map((upload) => ({
                ...upload,
                userInfo: `Uploaded by: ${user.name}`,
              }));
            }
            return [];
          });

          setDocuments(allUploads);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const openDoc = (location) => {
    const url = `https://www.absolutelypowerfulclasses.com/${location}`;
    window.open(url, "_blank");
  };

  const getUser = (document) => {
    if (document.officer !== null) {
      return (
        <>
          <p className="uploads--card-sm_txt">
            Officer View: {document.officer.user.name}
          </p>
          <p className="uploads--card-sm_txt">
            Student Name: {document.student_name}
          </p>
        </>
      );
    } else if (document.student !== null) {
      return (
        <p className="uploads--card-sm_txt student">
          Student View: {document.student.user.name}
        </p>
      );
    } else {
      return "---";
    }
  };

  return (
    <div className="uploads-container">
      {isLoading ? (
        <p>Loading...</p>
      ) : documents.length > 0 ? (
        <>
          {documents.map((document, index) => (
            <div key={index} className="uploads--card">
              <p>{document.userInfo}</p>
              {getUser(document)}
              <h3>{document.display_name}</h3>
              <p>{document.date}</p>
              <hr className="uploads--card--hr" />
              <button
                className="uploads--card--btn"
                onClick={() => openDoc(document.location)}
              >
                Download Report
              </button>
            </div>
          ))}
        </>
      ) : (
        <p>No documents available</p>
      )}
    </div>
  );
};

export default AllUploads;
