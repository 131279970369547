import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import ClientClassEnrollment from "./ClientClassEnrollment";
import { getOfficerCurrentClients } from "../../utils/api";

const CurrentClients = ({ user }) => {
  // Get students
  const [isLoading, setLoading] = useState(true);
  const [currentClients, setCurrentClients] = useState([]);

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const openModal = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  useEffect(() => {
    // Get Current Students from the db
    getOfficerCurrentClients(user.id)
      .then((res) => res.json())
      .then((data) => {
        console.log("Response data:", data[0]);
        if (data && data[0].students) {
          console.log("Students data:", data.students);
          setCurrentClients(data[0].students);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [user.id]);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <h1>Current Clients</h1>

            <div className="current-clients-container">
              {currentClients.map((student) => (
                <StudentDiv
                  key={student.id}
                  student={student}
                  openModal={openModal}
                />
              ))}
            </div>

            {isModalOpen && (
              <ClientClassEnrollment
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                student={selectedStudent}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const StudentDiv = ({ student, openModal }) => {
  return (
    <div className="current-clients-div">
      <p className="current-clients-name" onClick={() => openModal(student)}>
        {student.user.name}
      </p>
    </div>
  );
};

export default CurrentClients;
