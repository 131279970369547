import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import { getSession, createStripeSession } from "../../utils/api";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
// console.log(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ClassRegistration = ({ user }) => {
  const [session, setSession] = useState();
  const [isPayInFull, setIsPayInFull] = useState();
  const [stripeSession, setStripeSession] = useState();
  const [isLoading, setLoading] = useState(true);
  // Form States
  const [classAmount, setClassAmount] = useState(12);
  const [court_case, setCourtCase] = useState();
  const [judge, setJudge] = useState();

  // Array for selection
  const numArr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
  ];

  // Session Id
  const url = window.location.href;
  const id = url.split("/")[4];

  // Redirect URL for Stripe Session
  const baseUrl = url.split("/");
  const redirectUrl = `${baseUrl[0]}//${baseUrl[2]}/`;

  // Run Query Once
  useEffect(() => {
    getSession(id)
      .then((res) => res.json())
      .then((data) => {
        setSession(data);

        // console.log(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  // StripeSession variable will contain the checkout session
  // After the payment processes, users will be redirected to <path>/payment-success
  useEffect(() => {
    // redirect to Stripe once the stripeSession variable has stripeSession in it
    if (stripeSession) {
      stripePromise.then((res) => {
        res.redirectToCheckout({ sessionId: stripeSession.session });
      });
    }
  }, [stripeSession]);

  function handleFormSubmit(e) {
    e.preventDefault();

    if (isPayInFull) {
      handlePayInFull(e);
    } else {
      handlePayAsYouGo(e);
    }
  }

  function saveFormData(data, class_amount) {
    // Gather order data for queries (if payment successful)
    const formData = {
      court_case: court_case,
      classAmount: class_amount,
      judge: judge,
      session_id: parseInt(id),
      student_id: user.student_id,
      stripe_session: data.session,
      session_name: session.course.name,
      signupClassAmount: parseInt(classAmount),
    };

    // Save a copy of order data for page refresh
    localStorage.setItem("enrdta", JSON.stringify(formData));
  }

  // ------- PAY IN FULL ----------
  async function handlePayInFull(e) {
    e.preventDefault();
    const processingFee = 1 - 0.029;
    const classCount = parseInt(classAmount);

    // https://stackoverflow.com/questions/37077481/stripe-fee-calculation-formula
    // Formula: total = ((class price * class amount) + .30) / (1 - 2.9%)
    // .30: Stripe Flat fee
    // 2.9%: Stripe Processing percentage fee per transaction
    const oneClass = parseInt(session.course.price.split("$")[1]);
    const flatPrice = oneClass * classCount;
    const flatFee = flatPrice + 0.3;
    const total = flatFee / processingFee;
    const processingTotal = total - flatPrice;

    // Gather Data needed for Stripe Session:
    const stripeData = {
      productName: `${session.course.name} - Absolutely Powerful Classes`,
      productDescription: `${session.course.description} 
      Processing Fee: $${processingTotal.toFixed(2)}
      Qty ${classCount}, $${oneClass} each`,
      coursePrice: parseFloat(total.toFixed(2)),
      classQuantity: 1,
      currentUrl: redirectUrl,
      priorPage: "classRegistration",
    };

    console.log(stripeData);

    // Create the Stripe session in the server
    const response = await createStripeSession(stripeData);
    const data = await response.json();
    saveFormData(data, parseInt(classAmount));

    // Update stripeSession state to trigger useEffect
    setStripeSession(data);
  }

  // ------- PAY AS YOU GO ----------
  async function handlePayAsYouGo(e) {
    e.preventDefault();

    const processingFee = 1 - 0.029;

    // https://stackoverflow.com/questions/37077481/stripe-fee-calculation-formula
    // Formula: total = (class price + .30) / (1 - 2.9%)
    // .30: Stripe Flat fee
    // 2.9%: Stripe Processing percentage fee per transaction
    const flatPrice = parseInt(session.course.price.split("$")[1]);
    const flatFee = flatPrice + 0.3;
    const total = flatFee / processingFee;
    const processingTotal = total - flatPrice;

    // Gather Data needed for Stripe Session:
    const stripeData = {
      productName: `${session.course.name} - Absolutely Powerful Classes`,
      productDescription: `${session.course.description} 
     
      Processing Fee: $${processingTotal.toFixed(2)}`,
      coursePrice: total.toFixed(2),
      classQuantity: 1,
      currentUrl: redirectUrl,
      priorPage: "classRegistration",
    };

    // Create the Stripe session in the server
    const response = await createStripeSession(stripeData);
    const data = await response.json();
    saveFormData(data, 1);

    // Update stripeSession state to trigger useEffect
    setStripeSession(data);
  }

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      {/* Main ClassRegistration Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content class_registration">
            <h1>ClassRegistration</h1>
            <p className="registration_title">{session.course.name} </p>{" "}
            <p className="registration_title">
              {session.start_date} - {session.end_date}
            </p>
            {/* Registration Form */}
            <form onSubmit={handleFormSubmit}>
              {/* Amount of Classes wanted */}
              <label htmlFor="classAmount">
                How many classes would you like to sign up for?
              </label>
              <br></br>
              <select
                name="classAmount"
                id="classAmount"
                form="classAmount"
                value={classAmount}
                onChange={(e) => setClassAmount(e.target.value)}
              >
                {numArr.map((number) => (
                  <option key={`select${number}`} value={number}>
                    {number}
                  </option>
                ))}
              </select>
              <br></br>
              <br></br>

              {/* Court Case */}
              <label htmlFor="courtCase">Court Case (optional)</label>
              <br></br>
              <input
                type="courtCase"
                id="courtCase"
                name="courtCase"
                defaultValue={court_case}
                onChange={(e) => setCourtCase(e.target.value)}
              />
              <br></br>
              <br></br>

              {/* Judge */}
              <label htmlFor="judge">Judge (optional)</label>
              <br></br>
              <input
                type="judge"
                id="judge"
                name="judge"
                defaultValue={judge}
                onChange={(e) => setJudge(e.target.value)}
              />
              <br></br>
              <br></br>

              <Link to="/privacy-policy" className="privacy-policy_sm_txt">
                Read Terms and Conditions
              </Link>
              <br></br>
              <br></br>
              {/* Terms and Conditions checkbox */}
              <input
                type="checkbox"
                id="termsAndConditions"
                name="termsAndConditions"
                value="Bike"
                required
              />
              <label
                htmlFor="termsAndConditions"
                className="registration_sm_txt"
              >
                I agree to terms and conditions
              </label>
              <br></br>
              <br></br>

              <div className="pay-btns-container">
                <h3 className="class-payment-header">Payment Options</h3>
                <div>
                  <p>
                    Pay the full $
                    {parseInt(session.course.price.split("$")[1]) * classAmount}{" "}
                    today.
                  </p>
                  <input
                    type="submit"
                    className="signup--form--button registration_form_btn"
                    onClick={() => setIsPayInFull(true)}
                    value="Pay In Full"
                  />
                </div>
                <hr></hr>
                <div>
                  <p>
                    Pay ${parseInt(session.course.price.split("$")[1])} today,
                    and each class as you go.
                  </p>
                  <input
                    type="submit"
                    className="signup--form--button registration_form_btn"
                    onClick={() => setIsPayInFull(false)}
                    value="Pay First Class"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClassRegistration;
