import React, { useState, useEffect } from "react";
import { getStudentDocByInst } from "../../utils/api";

// Utility function to format date
const formatDate = (dateString) => {
  if (dateString && dateString !== null) {
    const formattedDate = dateString.split("/");
    return `${formattedDate[0]}-${formattedDate[1]}-${formattedDate[2]}`;
  }
  return null;
};

const UploadsByStudents = ({ user }) => {
  const [studentDocuments, setStudentDocuments] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    //Get uploaded documents 
    getStudentDocByInst(user.id)
      .then((res) => res.json())
      .then((data) => {
        // Format document upload date
        data.forEach((document) => {
          document.date = formatDate(document.date);
        });

        setStudentDocuments(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [user.id]);

  const openDoc = (location) => {
    const url = `https://www.absolutelypowerfulclasses.com/${location}`;
    window.open(url, "_blank");
  };

  return (
    <div className="uploads-container">
      {isLoading ? (
        <p>Loading...</p>
      ) : studentDocuments.length > 0 ? (
        <>
          {studentDocuments.map((document, index) => (
            <div key={index} className="uploads--card">
              <p>Student: {document.student.user.name}</p>
              <h3>{document.display_name}</h3>
              <p>{document.date}</p>
              <hr className="uploads--card--hr" />
              <button
                className="uploads--card--btn"
                onClick={() => openDoc(document.location)}
              >
                Download Report
              </button>
            </div>
          ))}
        </>
      ) : (
        <p>No documents available</p>
      )}
    </div>
  );
};

export default UploadsByStudents;
