import React, { useState, useEffect } from "react";
import {
  getLesson,
  postResponse,
  getRoster,
  updateRosterLessons,
} from "../../utils/api";
import DashboardNav from "../../components/DashboardNav";
import { useNavigate } from "react-router-dom";

const Lesson = ({ user }) => {
  const url = window.location.href;
  const id = url.split("/")[4];
  const session_id = url.split("/")[5];
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  // db data
  const [lesson, setLesson] = useState();
  const [rosterData, setRosterData] = useState();

  // Form Data
  const [canSubmit, setCanSubmit] = useState(false);
  const [stu_response, setStudentResponse] = useState("");

  // Run Query Once
  useEffect(() => {
    if (stu_response.length > 240) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [stu_response]);

  // Run Query Once
  useEffect(() => {
    getLesson(id)
      .then((res) => res.json())
      .then((data) => {
        setLesson(data);

        getRoster(session_id, user.student_id)
          .then((res) => res.json())
          .then((data) => {
            setRosterData(data[0]);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Fetch 1: Post the response to the database
    postResponse({
      stu_response: stu_response,
      lesson_id: id,
      student_id: user.student_id,
    })
      .then(() => {
        const updatedRosterData = {
          lessons_complete: rosterData.lessons_complete + 1,
        };

        // Fetch 2: Update the Lessons completed count
        updateRosterLessons(updatedRosterData, rosterData.id).then(() => {
          // re-route to the Assessment Success Page
          navigate(`/assessment-success/${rosterData.session_id}`);
        });
      })
      .catch((err) => console.log(err));
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Lesson Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content lessons-page">
            <h1>{lesson.title}</h1>
            <p style={{ whiteSpace: "pre-wrap" }}>{lesson.content}</p>

            <form onSubmit={handleFormSubmit}>
              <label htmlFor="assignment">{lesson.assignment}</label>
              <br></br>
              <textarea
                id="assignment"
                name="assignment"
                value={stu_response}
                placeholder="Write your response here"
                onChange={(e) => setStudentResponse(e.target.value)}
              ></textarea>
              <br></br>
              <p>{stu_response.length} / 240 </p>
              <br></br>

              {canSubmit === false ? (
                <input
                  className="signup--form--button lesson_btn disabled"
                  type="submit"
                  value="Submit"
                  disabled
                />
              ) : (
                <input
                  className="signup--form--button lesson_btn"
                  type="submit"
                  value="Submit"
                />
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Lesson;
