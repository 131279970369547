import React, { useState } from "react";
import { udpateResponse } from "../../utils/api";

const AssignmentReviewModal = ({ assignment, setIsModalOpen }) => {
  const currentAssignment = assignment;
  // Instructor Feedback Input States
  const [insFeedback, setInsFeedback] = useState(
    currentAssignment.ins_feedback || ""
  );
  const [insPrivateNotes, setInsPrivateNotes] = useState(
    currentAssignment.ins_private_notes || ""
  );

  const handleStatusUpdate = (e, status) => {
    e.preventDefault();

    const assignment_id = currentAssignment.id;

    // Update the status based on the button clicked
    const updatedAssignment = {
      status: status === "approve" ? "Completed" : "Resubmit",
      ins_feedback: insFeedback,
      ins_private_notes: insPrivateNotes,
    };

    // Fetch to update the Response record in the db
    udpateResponse(assignment_id, updatedAssignment)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        // Reload the page to update the "Assignment's Table"
        setIsModalOpen(false);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="modal--fixed">
              <div className="class-enrollment--container">
                <button
                  className="close-btn"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  X
                </button>
                <p className="bold assignment-review--name">
                  {currentAssignment.student.user.name}
                </p>
                <p className="bold">
                  {currentAssignment.lesson.courses[0].name}
                </p>

                <p className="bold">{currentAssignment.lesson.title}</p>

                <details>
                  <summary className="link">Reading Excerpt</summary>
                  <p>{currentAssignment.lesson.content}</p>
                </details>

                <p>Response</p>
                <p>{currentAssignment.stu_response}</p>

                <form>
                  <label htmlFor="notes">Instructors Notes:</label>
                  <textarea
                    id="notes"
                    className="attendance-notes"
                    name="notes"
                    value={insFeedback}
                    onChange={(e) => setInsFeedback(e.target.value)}
                    required
                  ></textarea>

                  <label htmlFor="private-notes">
                    Private Instructors Notes:
                  </label>
                  <textarea
                    id="private-notes"
                    className="attendance-notes"
                    name="private-notes"
                    value={insPrivateNotes}
                    onChange={(e) => setInsPrivateNotes(e.target.value)}
                  ></textarea>

                  <button
                    className="signup-button assignment--btn"
                    onClick={(e) => handleStatusUpdate(e, "resubmit")}
                  >
                    Send Back
                  </button>
                  <button
                    className="signup-button assignment--btn"
                    onClick={(e) => handleStatusUpdate(e, "approve")}
                  >
                    Approve
                  </button>
                </form>

                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AssignmentReviewModal;
