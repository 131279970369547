import React, { useState, useEffect } from "react";
import {
  getUserProfile,
  addFile,
  udpateUser,
  updateTokenImage,
} from "../utils/api";
import DashboardNav from "../components/DashboardNav";
import Auth from "../utils/auth";

const Profile = ({ user }) => {
  const [isLoading, setLoading] = useState(true);
  // User Type States
  const [userData, setUserData] = useState();
  // Holds uploaded profile image
  const [profileImage, setProfileImage] = useState();

  // Run Query Once
  useEffect(() => {
    getUserProfile(user.id)
      .then((res) => res.json())
      .then((data) => {
        setUserData({
          name: data.name,
          email: data.email,
          phone: data.phone,
          image: data.image,
          address: data.address,
        });
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  /*
    Three fetch requests due to different headers types + content:
    1. addFile: uploads the profile image and returns the new file name
    2. udpateUser: updates the user model with form data (+ new file name if applicable)
    3. updateTokenImage: updates the token with new image for display in the header
  */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Requests to handle updating the User and Instructor Models
    const updateUser = async (fileName) => {
      const userFormData = userData;

      // IF a new image is uploaded, add the image name to the user model
      if (fileName !== undefined) {
        userFormData.image = fileName;
      }

      // Fetch 2: Update the User model's data
      const userResponse = await udpateUser(userFormData, user.id);

      // IF a new image is uploaded, generate a new token
      if (fileName !== undefined) {
        const userData = await userResponse.json();
        console.log(userData);

        // Fetch 3: Update the User's token with the new image
        const response = await updateTokenImage(user.id);
        const data = await response.json();
        const { token } = await data;
        // If successfull, save the new token
        Auth.login(token);
      }
    };

    // Handle profile image first
    if (profileImage !== undefined) {
      // Fetch 1: Upload profile photo
      const response = await addFile(profileImage);
      const data = await response.json();
      const fileName = await data.fileName;

      // Complete user fetch with the new file name
      updateUser(fileName).then(() => {
        window.location.reload();
      });
    } else {
      updateUser().then(() => {
        window.location.reload();
      });
    }
  };

  const handleImageChange = (e) => {
    // Don't update state if the user clicks "cancel"
    if (!e.target.files[0]) {
      return;
    }

    // Format File for fetch request
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setProfileImage(formData);
  };

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Profile Content */}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <form
              className="profile-form"
              onSubmit={handleFormSubmit}
              encType="multipart/form-data"
            >
              <div className="profile-form-photo-container">
                <img
                  className="profile-form-photo help"
                  src={
                    !userData.image
                      ? "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png"
                      : `${userData.image}`
                  }
                  alt="a student"
                />
                <div className="flexCenter">
                  <input
                    className="profile-form-photo-input"
                    type="file"
                    accept="image/png, image/jpeg"
                    name="file"
                    onChange={handleImageChange}
                  ></input>
                </div>
              </div>
              {/* Name */}
              <div className="one_line">
                <label htmlFor="name">Full Name:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="text"
                  id="name"
                  name="name"
                  defaultValue={userData.name}
                  onChange={(e) => {
                    setUserData({ ...userData, name: e.target.value });
                  }}
                  required
                />
              </div>

              {/* Address */}
              <div className="one_line">
                <label htmlFor="address">Address:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="text"
                  id="address"
                  name="address"
                  defaultValue={userData.address}
                  onChange={(e) =>
                    setUserData({ ...userData, address: e.target.value })
                  }
                  required
                />
              </div>

              {/* Phone Number */}
              <div className="one_line">
                <label htmlFor="phone">Phone Number:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="tel"
                  id="phone"
                  name="phone"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  defaultValue={userData.phone}
                  onChange={(e) =>
                    setUserData({ ...userData, phone: e.target.value })
                  }
                  required
                />
              </div>

              {/* Email */}
              <div className="one_line">
                <label htmlFor="email">Email:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="email"
                  id="email"
                  name="email"
                  defaultValue={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  required
                />
              </div>

              {/* Password */}
              <div className="one_line">
                <label htmlFor="password">Password:</label>
                <br></br>
                <input
                  className="row_one_label"
                  type="password"
                  id="password"
                  name="password"
                  onChange={(e) => {
                    // If the user types a password, then removes it, don't send an empty string to the DB
                    if (e.target.value.trim() === "") {
                      delete userData.password;
                    } else {
                      setUserData({
                        ...userData,
                        password: e.target.value.trim(),
                      });
                    }
                  }}
                />
              </div>

              <div className="submitBtnContainer">
                <input
                  className="signup--form--button"
                  type="submit"
                  value="Submit Changes"
                />
              </div>
            </form>

            {/* Temporary Placement of Logout Button */}
            <div style={{ margin: "20px " }} className="logout_center">
              <button className="logout" onClick={() => Auth.logout()}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
