import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import {
  getStudentClasses,
  getSession,
  createStripeSession,
  getRoster,
  getLessonOrder,
} from "../../utils/api";
import { getOrderedLessons } from "../../utils/orderedLessons";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import intakePdf from "../../assets/Absolutely-Powerful-Classes-Intake-Form.pdf";
import consentDox from "../../assets/Absolutely-Powerful-Classes-Consent-Program-Rules.pdf";
import { Link } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Classes = ({ user }) => {
  const [sessions, setSessions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [lessonOrder, setLessonOrder] = useState([]);

  // State for current session data
  const [session, setSession] = useState();
  const [stripeSession, setStripeSession] = useState();
  // State to store session id's
  const [sessionIds, setSessionIds] = useState([]);
  // State for current session id
  const [selectedSessionId, setSelectedSessionId] = useState();
  // Run Query Once
  useEffect(() => {
    getStudentClasses(user.student_id)
      .then((res) => res.json())
      .then((data) => {
        if (data.sessions && data.sessions.length) {
          const ids = data.sessions.map((session) => session.id);
          setSessionIds(ids);
          setSessions(data.sessions);
          setSelectedSessionId(data.sessions[0].id);
        }
        getLessonOrder()
          .then((res) => res.json())
          .then((data) => {
            if (data[0]) {
              const record = data[0];
              const order = JSON.parse(record.content);
              setLessonOrder(order);
            }
          })
          .catch((err) => console.log(err));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  // Redirect URL for Stripe Session
  const url = window.location.href;
  const baseUrl = url.split("/");
  const redirectUrl = `${baseUrl[0]}//${baseUrl[2]}/`;
  // Run Query Once
  useEffect(() => {
    async function fetchData() {
      try {
        // Check if the selectedSessionId is null
        if (!selectedSessionId) {
          console.error("selectedSessionId is null");
          return;
        }

        // Get session data using the selectedSessionId
        const res = await getSession(selectedSessionId);
        const data = await res.json();

        // Check if the response data is valid
        if (data) {
          const sessionId = data.id;
          console.log("Session ID:", sessionId);
          // Set session data to state
          setSession(data);
        } else {
          console.error("Data is undefined in the response");
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
    fetchData();
  }, [selectedSessionId]);
  // StripeSession variable will contain the checkout session
  // After the payment processes, users will be redirected to <path>/payment-success
  useEffect(() => {
    // redirect to Stripe once the stripeSession variable has stripeSession in it
    if (stripeSession) {
      stripePromise.then((res) => {
        res.redirectToCheckout({ sessionId: stripeSession.session });
      });
    }
  }, [stripeSession]);
  async function handlePayForSession(e, sessionId) {
    e.preventDefault();

    try {
      // Set the selected session ID
      setSelectedSessionId(sessionId);

      // Fetch session data using the provided sessionId
      const res = await getSession(sessionId);
      const sessionData = await res.json();
      const processingFee = 1 - 0.029;

      // https://stackoverflow.com/questions/37077481/stripe-fee-calculation-formula
      // Formula: total = (class price + .30) / (1 - 2.9%)
      // .30: Stripe Flat fee
      // 2.9%: Stripe Processing percentage fee per transaction
      const flatPrice = parseInt(sessionData.course.price.split("$")[1]);
      const flatFee = flatPrice + 0.3;
      const total = flatFee / processingFee;
      const processingTotal = total - flatPrice;

      const stripeData = {
        productName: `${sessionData.course.name} - Absolutely Powerful Classes`,
        productDescription: `${
          sessionData.course.description
        } Processing Fee: $${processingTotal.toFixed(2)}`,
        coursePrice: total.toFixed(2),
        classQuantity: 1,
        currentUrl: redirectUrl,
        priorPage: "classesPage",
      };

      // Create the Stripe session in the server
      const response = await createStripeSession(stripeData);
      const data = await response.json();
      // Gather Roster data for queries (if payment successful)
      const rosterResponse = await getRoster(sessionData.id, user.student_id);
      const rosterData = await rosterResponse.json();
      const studentData = {
        classAmount: rosterData[0].class_amount,
        session_id: sessionData.id,
        roster_id: rosterData[0].id,
        student_id: user.student_id,
        stripe_session: data.session,
        session_name: sessionData.course.name,
      };
      // Save a copy of order data for page refresh
      localStorage.setItem("paygdata", JSON.stringify(studentData));
      // Update stripeSession state to trigger useEffect
      setStripeSession(data);
    } catch (err) {
      console.error(err);
    }
  }
  // onclick intake download
  const downloadIntakeForm = () => {
    window.open(intakePdf, "_blank");
  };
  // onclick consent rules packet download
  const downloadConsentRules = () => {
    window.open(consentDox, "_blank");
  };

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />

      {/* Main Classes Content*/}
      <section>
        <div className="dashboard">
          <button onClick={downloadIntakeForm} className="intake-form-btn">
            Intake Form Assessment
          </button>
          <button onClick={downloadConsentRules} className="intake-form-btn">
            Consent Rules
          </button>
          <div className="dashboard_content">
            <h1 style={{ paddingTop: "0" }}>Classes</h1>

            {/* Loop through and display class info */}
            <div className="class-card--container">
              {sessions.map((session) => (
                <SessionCards
                  session={session}
                  key={session.id}
                  handlePayForSession={handlePayForSession}
                  setSelectedSessionId={setSelectedSessionId}
                  lessonOrder={lessonOrder}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SessionCards = ({
  session,
  handlePayForSession,
  setSelectedSessionId,
  lessonOrder,
}) => {
  const navigate = useNavigate();
  const orderedLessons = getOrderedLessons(session.course.lessons, lessonOrder);

  const totalLessons = session.roster.class_amount;
  const submitted = session.roster.lessons_complete;
  const progress = (submitted / totalLessons) * 100;

  // Instructor Comments will hold an id for the repsonse of the feedback if applicable
  let instructorComments;

  const submissions = orderedLessons.filter(
    // return if there is a response recorded
    (lesson) => lesson.responses[0] !== undefined
  );

  if (submitted > 0) {
    const availableFeedback = submissions.filter(
      (submission) => submission.responses[0].status === "Resubmit"
    );

    // console.log(availableFeedback);
    if (availableFeedback.length !== 0) {
      instructorComments = availableFeedback[0].responses[0].id;
    }
  }

  const incomplete = orderedLessons.filter(
    // return if there is NOT a response recorded
    (lesson) => !lesson.responses[0]
  );
  // console.log(incomplete);

  const checkProgress = () => {
    switch (progress) {
      case 0:
        if (incomplete[0] !== undefined) {
          if (incomplete[0].id !== undefined) {
            return (
              <button
                className="continue_button"
                onClick={() => {
                  navigate(`/lesson/${incomplete[0].id}/${session.id}`);
                }}
              >
                Start Course
              </button>
            );
          }
        } else {
          return (
            <button
              className="continue_button"
              onClick={() => {
                navigate(`/lesson/comming-soon`);
              }}
            >
              Start Course
            </button>
          );
        }
        break;
      case 100:
        return <p>Lessons Completed</p>;
      default:
        if (incomplete[0] !== undefined) {
          if (incomplete[0].id !== undefined) {
            return (
              <button
                className="continue_button"
                onClick={() => {
                  navigate(`/lesson/${incomplete[0].id}/${session.id}`);
                }}
              >
                Continue
              </button>
            );
          }
        } else {
          return (
            <button
              className="continue_button"
              onClick={() => {
                navigate(`/lesson/comming-soon`);
              }}
            >
              Continue
            </button>
          );
        }
    }
  };
  const handlePayClick = (e, sessionId) => {
    setSelectedSessionId(sessionId);
    handlePayForSession(e, sessionId);
  };

  return (
    <div className="class-card pb">
      <h3 style={{ marginBottom: "7px" }}>{session.course.name}</h3>
      <button
        className="class-card--btn"
        style={{ padding: ".7em", marginBottom: "0px" }}
        onClick={(e) => handlePayClick(e, session.id)}
      >
        Pay for a Session
      </button>

      <p style={{ marginTop: "7px" }}>
        {session.start_date} - {session.end_date}
      </p>
      <div className="progress_bar">
        <div
          className="progress_status"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      <br></br>

      {checkProgress()}
      <p>
        {submitted}/{totalLessons}
      </p>

      {instructorComments !== undefined ? (
        <a href={`/instructor-feedback/${instructorComments}`}>
          See Instructors Comments
        </a>
      ) : (
        <p className="placeholder-space"></p>
      )}

      <hr className="class-card--hr"></hr>
      <a href={session.instructor.zoom_link} target="_blank" rel="noreferrer">
        <b>Group Session Zoom</b>
      </a>
      <br></br>
      <Link className="group-rules-link" to={"/group-rules"}>
        View Group Session Rules
      </Link>
    </div>
  );
};

export default Classes;
