import React, { useState, useEffect } from "react";
import DashboardNav from "../../components/DashboardNav";
import { getPastStudents } from "../../utils/api";
import { Link } from "react-router-dom";

const PastStudents = ({ user }) => {
  // state for past students and search bar
  const [pastStudents, setPastStudents] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    // get past students
    getPastStudents(user.id)
      .then((res) => res.json())
      .then((data) => {
        if (data.message !== "There are no Students found with this id") {
          setPastStudents(data);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [user.id]);

  useEffect(() => {
    // Filter the students based on the search term
    const filteredStudents = filterDuplicates(pastStudents); // Remove duplicate students
    setSearchResults(filteredStudents); // Update the search results state
  }, [searchTerm, pastStudents]);

  // Function to filter duplicate students
  const filterDuplicates = (array) => {
    const uniqueStudents = {};
    const filteredArray = [];

    array.forEach((student) => {
      const { name, email, phone } = student;
      const key = `${name}_${email}_${phone}`;
      if (!uniqueStudents[key]) {
        uniqueStudents[key] = true;
        filteredArray.push({ id: student.student.id, name, email, phone });
      }
    });

    return filteredArray;
  };
  // student row component
  const StudentRow = ({ student }) => {
    return (
      <tr key={student.id}>
        <td>
          <Link to={`/student-info/${student.id}`}>{student.name}</Link>
        </td>
        <td>{student.phone}</td>
        <td>{student.email}</td>
      </tr>
    );
  };

  // Event handler for input change in search bar
  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setShowDropdown(searchTerm !== ""); // Show the dropdown if search term is not empty
  };

  // Function for rendering the dropdown list of search results
  const renderDropdown = () => {
    const filteredResults = searchResults.filter((student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredResults.length === 0 || searchTerm === "") {
      return null;
    }

    return (
      <div className={`dropdown-container ${searchTerm ? "show" : ""}`}>
        <ul className="dropdown">
          {filteredResults.map((student) => (
            <li
              key={student.id}
              onClick={() => {
                setSearchTerm(student.name);
                setSearchResults([]);
              }}
            >
              <Link
                to={`/student-info/${student.id}`}
                className="dropdown-link"
              >
                {student.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  // Fix: stop flashing after state updates
  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="past--sessions-header">
              <h1 className="past--sessions-h1">Students</h1>
              {/* <!-- Past Sessions Header --> */}
              <form className="past--sessions-searchbar">
                <label htmlFor="search-term" className="hidden">
                  Search the site:
                </label>
                <div className="search-wrapper">
                  <input
                    className="past--sessions-searchbar-input"
                    type="search"
                    id="search-term"
                    placeholder="Search..."
                    name="q"
                    value={searchTerm}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {renderDropdown()}
                </div>
                <button className="past" type="submit">
                  Search
                </button>
              </form>
              {/* <!-- Roster Table --> */}
              <div>
                <h2 className="hidden">Roster</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone Numbers</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.length ? (
                      <>
                        {searchResults.map((student) => (
                          <StudentRow key={student.id} student={student} />
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td>No Students available</td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PastStudents;
