import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Attendance from "./Attendance";

const ClassEnrollment = ({ user, closeModal, meeting }) => {
  const students = meeting.session.students || [];
  const zoomLink = meeting.session.instructor.zoom_link;

  // Assignment Review State and Modal
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);

  const openModal = () => {
    setIsAttendanceModalOpen(true);
  };

  const StudentRow = (student) => {
    return (
      <tr>
        <td>
          <Link to={`/student-info/${student.student.id}`}>
            {student.student.user.name}
          </Link>
        </td>
        <td>{student.student.user.phone}</td>
        <td>{student.student.user.email}</td>
      </tr>
    );
  };

  return (
    <>
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="modal--fixed">
              <div className="class-enrollment--container">
                <button className="close-btn" onClick={() => closeModal()}>
                  X
                </button>

                <h1>{meeting.session.course.name}</h1>

                {/* Links Attendance Modal for this meeting */}
                <button
                  className="assessment_button"
                  style={{ marginTop: "0px", marginRight: "10px" }}
                  onClick={() => openModal(false)}
                >
                  Take Attendance
                </button>
                {isAttendanceModalOpen && (
                  <Attendance
                    user={user}
                    setIsAttendanceModalOpen={setIsAttendanceModalOpen}
                    isAttendanceModalOpen={isAttendanceModalOpen}
                    meeting={meeting}
                  />
                )}
                <button
                  className="assessment_button"
                  style={{ marginTop: "0px" }}
                  // onClick --> Open zoom link in a new window
                  onClick={() => window.open(zoomLink, "_blank")}
                >
                  Zoom
                </button>

                <div>
                  <h2 className="hidden">Roster</h2>
                  <table>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Phone Numbers</th>
                        <th>Email</th>
                      </tr>

                      {students.length ? (
                        <>
                          {students.map((student) => (
                            <StudentRow key={student.id} student={student} />
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td>No Students Enrolled</td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClassEnrollment;
