import React, { useState, useEffect } from "react";
import { postOrders, updateRosterClassAmount } from "../../utils/api";
import DashboardNav from "../../components/DashboardNav";
import { useNavigate } from "react-router-dom";
import "./payment.css";

const SinglePaymentSuccess = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState();

  // Run Query Once
  useEffect(() => {
    async function fetchData() {
      // Get the enrollment data from local storage
      const enrollmentData = JSON.parse(localStorage.getItem("paygdata"));

      if (enrollmentData === null) {
        navigate("/classes");
      } else {
        const { session_id, classAmount, stripe_session, roster_id } =
          enrollmentData;

        // Gather Order Data
        const orderData = {
          session_id: session_id,
          stripe_session: stripe_session,
          student_id: user.student_id,
          class_amount: classAmount,
        };

        // Gather Notice Data
        const rosterData = {
          class_amount: classAmount + 1,
        };

        // Creates a record of the order
        postOrders(orderData)
          .then(() => {
            // Udpate Student Class amount in the db
            updateRosterClassAmount(rosterData, roster_id)
              .then(() => {
                setPaymentData(stripe_session);
                setLoading(false);
                // localStorage.removeItem('paygdata');
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <DashboardNav user={user} />
        <section>
          <div className="dashboard">
            <div className="dashboard_content"></div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <DashboardNav user={user} />
      {/* Main Dashboard Content*/}
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="payment-success-page">
              <div className="success-icon success-ring-color">
                <span className="checkmark-line line-right"></span>
                <span className="checkmark-line line-short"></span>
              </div>
              <div className="payment-content">
                <h1>Thank you for your payment!</h1>
                <p className="confirmation-number">
                  Confirmation Number: {paymentData}
                </p>

                <hr className="payment-success-hr"></hr>
                <p className="confirmation-message">
                  Your payment is processing and Our staff will be in touch with
                  you shortly. Check your email for next steps!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SinglePaymentSuccess;
