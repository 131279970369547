import React, { useState } from "react";
import Login from "../Login";
import Signup from "../Signup";

const Modal = ({ setIsModalOpen, isModalOpen }) => {
  const [loginDisplayed, setloginDisplayed] = useState(false);

  return (
    <div className="modal">
      <div className="login--form-container">
        <header className="login--form-header">
          <button onClick={() => setIsModalOpen(!isModalOpen)}>X</button>
          <h2>Absolutely Powerful</h2>

          <ul>
            <li
              className={loginDisplayed === false ? "form--underline" : ""}
              onClick={() => setloginDisplayed(false)}
            >
              Sign Up
            </li>
            <li
              className={loginDisplayed === false ? "" : "form--underline"}
              onClick={() => setloginDisplayed(true)}
            >
              Log In
            </li>
          </ul>
        </header>

        {/* User Form */}
        <section>
          {
            //Display Form
            loginDisplayed === false ? <Signup /> : <Login />
          }
        </section>
      </div>
    </div>
  );
};

export default Modal;
