import React, { useState, useEffect } from "react";
import {
  getCourses,
  getInstructors,
  updateSession,
  createMeeting,
  getMeetings,
  deleteMeetings,
} from "../../utils/api";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ClassSchedule from "../InstructorViews/ClassSchedule";

const EditSession = ({ closeModal, session, user }) => {
  const [courses, setCourses] = useState();
  const [instructors, setInstructors] = useState();
  const [isLoading, setLoading] = useState(true);

  // Previous Values
  const previousCourse = session.course.id;
  const previousCourseName = session.course.name;
  const previousInstructor = session.instructor.id;
  const previousInstructorName = session.instructor.user.name;
  const previousTime = session.time;
  const previousStartDate = session.start_date;
  const previousEndDate = session.end_date;
  const [previousMeetingDates, setPreviousMeetingDates] = useState([]);
  const [previousMeetings, setPreviousMeetings] = useState([]);

  // Form States
  const [selectedCourse, setSelectedCourse] = useState(previousCourse);
  const [selectedInstructor, setSelectedInstructor] =
    useState(previousInstructor);
  const [value, onChange] = useState([previousStartDate, previousEndDate]);
  const [selectedTime, setSelectedTime] = useState(previousTime);
  const [values, setValues] = useState([]);

  const handleMeetings = async () => {
    const newDateObjs = [];
    const comparisonDateObjs = [];
    const deleteArr = [];

    for (let i = 0; i < values.length; i++) {
      const date = values[i];

      // Make sure values are js date objs
      if (date instanceof Date) {
        comparisonDateObjs.push(date.toDateString());
      } else {
        const formattedString = `${date.month.number}/${date.day}/${date.year}`;
        const dateObj = new Date(formattedString);
        newDateObjs.push(dateObj);
        comparisonDateObjs.push(dateObj.toDateString()); // used for delete comparison
      }
    }

    // Check what needs to be posted based on previous meeting dates
    const postArr = newDateObjs.filter(
      (meeting) => previousMeetingDates.indexOf(meeting.toDateString()) === -1
    );

    // Check what needs to be deleted based on previous meeting dates
    for (let i = 0; i < previousMeetings.length; i++) {
      const comparable = new Date(previousMeetings[i].date).toDateString();
      const exists = comparisonDateObjs.indexOf(comparable);

      if (exists === -1) {
        deleteArr.push(previousMeetings[i]);
      }
    }

    const updates = calcChanges(postArr, deleteArr);

    switch (updates) {
      case 3:
        const status = await Promise.all(
          deleteArr.map((date) => deleteMeetings(date.id)),
          postArr.map((date) =>
            createMeeting({ date: date, session_id: session.id })
          )
        );
        console.log("Status =>", status);
        break;
      case 2:
        const deleteStatus = await Promise.all(
          deleteArr.map((date) => deleteMeetings(date.id))
        );
        console.log("Status =>", deleteStatus);
        break;
      case 1:
        const postStatus = await Promise.all(
          postArr.map((date) =>
            createMeeting({ date: date, session_id: session.id })
          )
        );
        console.log("Status =>", postStatus);
        break;
      default:
        console.log("nothing to do here...");
        break;
    }
  };

  // Calculate what needs to be changed
  const calcChanges = (postArr, deleteArr) => {
    // 0 = don't update anything
    // 1 = post meetings only
    // 2 = delete meeting only
    // 3 = post and delete meetings

    let sum = 0;

    if (postArr[0]) {
      sum += 1;
    }
    if (deleteArr[0]) {
      sum += 2;
    }

    return sum;
  };
  // Form Submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      course_id: selectedCourse,
      instructor_id: selectedInstructor,
      start_date: value[0],
      end_date: value[1],
      time: selectedTime,
    };

    // Sequelize will throw an error if you try to update a model with the same data
    if (
      previousCourse === selectedCourse &&
      previousInstructor === selectedInstructor &&
      previousTime === selectedTime &&
      previousStartDate === value[0] &&
      previousEndDate === value[1]
    ) {
      // Don't Update Session
      handleMeetings().then(() => {
        window.location.reload();
      });
    } else {
      // PUT Session
      updateSession(formData, session.id)
        .then(() => {
          handleMeetings().then(() => {
            window.location.reload();
          });
        })
        .catch((err) => console.log(err));
    }
  };

  // Run Query Once
  useEffect(() => {
    const dbCourses = getCourses()
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((err) => console.log(err));

    const dbInstructors = getInstructors()
      .then((res) => res.json())
      .then((data) => {
        setInstructors(data);
      })
      .catch((err) => console.log(err));
    const dbMeetings = getMeetings(session.id)
      .then((res) => res.json())
      .then((data) => {
        setPreviousMeetings(data);

        // Display previous values to the calendar
        if (data[0]) {
          const existingMeetings = data.map(
            (meeting) => new Date(meeting.date)
          );
          setValues(existingMeetings);
        } else {
          console.log("auto first meeting");
          // If no meetings auto-populate first day as first meeting
          const firstMeeting = new Date(previousStartDate);
          setValues([firstMeeting]);
        }

        // Store previous values (date only) for comparison
        const formattedMeetings = data.map((meeting) =>
          new Date(meeting.date).toDateString()
        );
        setPreviousMeetingDates(formattedMeetings);
      })
      .catch((err) => console.log(err));

    Promise.all([dbCourses, dbInstructors, dbMeetings]).then(() => {
      setLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <section>
        <div className="dashboard">
          <div className="dashboard_content">
            <div className="modal--fixed">
              <div className="class-enrollment--container">
                <button
                  className="close-btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  X
                </button>

                <h1>Edit a Session</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section>
      <div className="dashboard">
        <div className="dashboard_content">
          <div className="modal--fixed">
            <div className="class-enrollment--container">
              <button
                className="close-btn"
                onClick={() => {
                  closeModal();
                }}
              >
                X
              </button>

              <h1>Edit a Session</h1>

              <form onSubmit={handleFormSubmit}>
                <div className="grid-1X2">
                  <div>
                    {" "}
                    {/* Course Selection */}
                    <label htmlFor="course">
                      <p>Select a Course:</p>
                    </label>
                    <br />
                    <select
                      name="course"
                      id="course"
                      onChange={(e) => setSelectedCourse(e.target.value)}
                      required
                    >
                      <option value={previousCourse} hidden>
                        {previousCourseName}
                      </option>
                      {courses.map((course) => (
                        <option key={course.id} value={course.id}>
                          {course.name}
                        </option>
                      ))}
                    </select>
                    <br />
                    {/* Instructor Selection */}
                    <label htmlFor="instructor">
                      <p>Select an Instructor:</p>
                    </label>
                    <br />
                    <select
                      name="instructor"
                      id="instructor"
                      required
                      onChange={(e) => setSelectedInstructor(e.target.value)}
                    >
                      <option value={previousInstructor} hidden>
                        {previousInstructorName}
                      </option>
                      {instructors.map((instructor) => (
                        <option key={instructor.id} value={instructor.id}>
                          {instructor.user.name}
                        </option>
                      ))}
                    </select>
                    <br />
                    {/* Time Selection */}
                    <label htmlFor="time">
                      <p>Set a Time:</p>
                    </label>
                    <br />
                    <select
                      name="time"
                      id="time"
                      required
                      onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value={previousTime} hidden>
                        {previousTime}
                      </option>
                      <option value="8:00am - 9:00am">8:00am - 9:00am</option>
                      <option value="9:00am - 10:00am">9:00am - 10:00am</option>
                      <option value="10:00am - 11:00am">
                        10:00am - 11:00am
                      </option>
                      <option value="11:00am - 12:00pm">
                        11:00am - 12:00pm
                      </option>
                      <option value="12:00pm - 1:00pm">12:00pm - 1:00pm</option>
                      <option value="1:00pm - 2:00pm">1:00pm - 2:00pm</option>
                      <option value="2:00pm - 3:00pm">2:00pm - 3:00pm</option>
                      <option value="3:00pm - 4:00pm">3:00pm - 4:00pm</option>
                      <option value="4:00pm - 5:00pm">4:00pm - 5:00pm</option>
                      <option value="5:00pm - 6:00pm">5:00pm - 6:00pm</option>
                      <option value="6:00pm - 7:00pm">6:00pm - 7:00pm</option>
                      <option value="7:00pm - 8:00pm">7:00pm - 8:00pm</option>
                    </select>
                    {session.instructor.user.id === user.id ? (
                      <div>
                        {/* Date Selection */}
                        <label htmlFor="date">
                          <p>Set Meeting Dates:</p>
                        </label>
                        <div>
                          <DatePicker
                            multiple
                            minDate={new Date(previousStartDate)}
                            maxDate={new Date(previousEndDate)}
                            format="MM/DD/YYYY"
                            value={values}
                            onChange={setValues}
                            placeholder="click to open and choose dates"
                            plugins={[<DatePanel sort="date" />]}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <br />
                  </div>

                  <div>
                    {/* Date Selection */}
                    <label htmlFor="date">
                      <p>Set date:</p>
                    </label>
                    <br />
                    <div>
                      <Calendar onChange={onChange} selectRange value={value} />
                    </div>
                    <br />
                  </div>
                </div>

                <button className="signup-button save-session">
                  Save Session
                </button>
              </form>

              <ClassSchedule />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditSession;
